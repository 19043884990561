import { Injectable } from '@angular/core'
import {
  HttpService,
  IExportFileResponse,
  INew_TableAction,
  TableActionsService
} from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import {
  IWorkOrdersSummaryRequest,
  IWorkOrdersSummaryResponse
} from '../interfaces/work-orders-summary.interface'
import { WORK_ORDERS_API_URL } from './work-orders-api-routes'
import { IWorkOrdersListResponse } from '../interfaces/work-orders-list.interface'

@Injectable({
  providedIn: 'root'
})
export class WorkOrdersService {
  constructor(private http: HttpService, private tableActionsService: TableActionsService) {}

  getWorkOrdersSummary(payload: IWorkOrdersSummaryRequest): Observable<IWorkOrdersSummaryResponse> {
    return this.http.post(WORK_ORDERS_API_URL.summary, payload)
  }

  exportWorkOrdersSummary(payload: IWorkOrdersSummaryRequest): Observable<IExportFileResponse> {
    return this.http.post(WORK_ORDERS_API_URL.exportSummary, payload)
  }

  getWorkOrdersList(info: INew_TableAction): Observable<IWorkOrdersListResponse> {
    return this.http.post(WORK_ORDERS_API_URL.list, this.tableActionsService.createRequestPayload(info))
  }

  exportWorkOrdersList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      WORK_ORDERS_API_URL.exportList,
      this.tableActionsService.createRequestPayload(info)
    )
  }
}
