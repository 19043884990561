import { Injectable } from '@angular/core'
import { TableFilterService } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { CommunicationStatus } from '../../enums/communication-status.enum'
import { ICustomersTypeExtendedResponse } from '../../interfaces/customers.interface'
import { CustomersService } from '../customers.service'
import {
  AddCommunication,
  AddCustomerTag,
  DeleteCustomerTag,
  GetCustomersInRadius,
  GetAgents,
  GetCommunication,
  GetCommunicationHistory,
  GetCommunicationsList,
  GetCommunicationType,
  GetCustomer,
  GetCustomerInvoices,
  GetCustomersList,
  GetCustomersTypes,
  GetCustomerTags,
  UpdateCommunication,
  UpdateCustomer,
  GetDeferredTypes
} from './customers.actions'
import { CustomersStateModel } from './customers.model'
import { ICommunicationsTypeExtendedResponse } from '../../interfaces/communication-type.interface'
import { IDeferredTypeExtendedResponse } from '../../interfaces/deferred.interface'

@State<CustomersStateModel>({
  name: 'customers'
})
@Injectable()
export class CustomersState {
  constructor(
    private customersService: CustomersService,
    private tableFilterService: TableFilterService
  ) {}

  @Selector()
  static customers(state: CustomersStateModel): ICustomersTypeExtendedResponse | undefined {
    if (!state.customers) {
      return
    }
    return {
      timePeriod: state.customers.timePeriod,
      customerTypes: state.customers.contactTypes.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,
        newInvoicesTotal: el.new?.invoices?.total ?? 0,
        newInvoicesTotalFormatted: el.new?.invoices?.totalFormatted,
        newInvoicesPercent: el.new?.invoices?.percent ?? 0,
        newInvoicesPercentFormatted: el.new?.invoices?.percentFormatted,

        newSalesTotal: el.new?.sales?.total ?? 0,
        newSalesTotalFormatted: el.new?.sales?.totalFormatted,
        newSalesPercent: el.new?.sales?.percent ?? 0,
        newSalesPercentFormatted: el.new?.sales?.percentFormatted,

        newAvgTicketTotal: el.new?.averageTicket?.total ?? 0,
        newAvgTicketTotalFormatted: el.new?.averageTicket?.totalFormatted,

        friendsInvoicesTotal: el.friends?.invoices?.total ?? 0,
        friendsInvoicesTotalFormatted: el.friends?.invoices?.totalFormatted,
        friendsInvoicesPercent: el.friends?.invoices?.percent ?? 0,
        friendsInvoicesPercentFormatted: el.friends?.invoices?.percentFormatted,

        friendsSalesTotal: el.friends?.sales?.total ?? 0,
        friendsSalesTotalFormatted: el.friends?.sales?.totalFormatted,
        friendsSalesPercent: el.friends?.sales?.percent ?? 0,
        friendsSalesPercentFormatted: el.friends?.sales?.percentFormatted,

        friendsAvgTicketTotal: el.friends?.averageTicket?.total ?? 0,
        friendsAvgTicketTotalFormatted: el.friends?.averageTicket?.totalFormatted,

        advocatesInvoicesTotal: el.advocates?.invoices?.total ?? 0,
        advocatesInvoicesTotalFormatted: el.advocates?.invoices?.totalFormatted,
        advocatesInvoicesPercent: el.advocates?.invoices?.percent ?? 0,
        advocatesInvoicesPercentFormatted: el.advocates?.invoices?.percentFormatted,

        advocatesSalesTotal: el.advocates?.sales?.total ?? 0,
        advocatesSalesTotalFormatted: el.advocates?.sales?.totalFormatted,
        advocatesSalesPercent: el.advocates?.sales?.percent ?? 0,
        advocatesSalesPercentFormatted: el.advocates?.sales?.percentFormatted,

        advocatesAvgTicketTotal: el.advocates?.averageTicket?.total ?? 0,
        advocatesAvgTicketTotalFormatted: el.advocates?.averageTicket?.totalFormatted,

        totalsInvoicesTotal: el.totals?.invoices?.total ?? 0,
        totalsInvoicesTotalFormatted: el.totals?.invoices?.totalFormatted,
        totalsInvoicesPercent: el.totals?.invoices?.percent ?? 0,
        totalsInvoicesPercentFormatted: el.totals?.invoices?.percentFormatted,

        totalsSalesTotal: el.totals?.sales?.total ?? 0,
        totalsSalesTotalFormatted: el.totals?.sales?.totalFormatted,
        totalsSalesPercent: el.totals?.sales?.percent ?? 0,
        totalsSalesPercentFormatted: el.totals?.sales?.percentFormatted
      })),
      grandTotal: {
        newInvoicesTotal: state.customers.totalNew?.invoices?.total ?? 0,
        newInvoicesTotalFormatted: state.customers.totalNew?.invoices?.totalFormatted,
        newInvoicesPercent: state.customers.totalNew?.invoices?.percent ?? 0,
        newInvoicesPercentFormatted: state.customers.totalNew?.invoices?.percentFormatted,

        newSalesTotal: state.customers.totalNew?.sales?.total ?? 0,
        newSalesTotalFormatted: state.customers.totalNew?.sales?.totalFormatted,
        newSalesPercent: state.customers.totalNew?.sales?.percent ?? 0,
        newSalesPercentFormatted: state.customers.totalNew?.sales?.percentFormatted,

        newAvgTicketTotal: state.customers.totalNew?.averageTicket?.total ?? 0,
        newAvgTicketTotalFormatted: state.customers.totalNew?.averageTicket?.totalFormatted,

        friendsInvoicesTotal: state.customers.totalFriends?.invoices?.total ?? 0,
        friendsInvoicesTotalFormatted: state.customers.totalFriends?.invoices?.totalFormatted,
        friendsInvoicesPercent: state.customers.totalFriends?.invoices?.percent ?? 0,
        friendsInvoicesPercentFormatted: state.customers.totalFriends?.invoices?.percentFormatted,

        friendsSalesTotal: state.customers.totalFriends?.sales?.total ?? 0,
        friendsSalesTotalFormatted: state.customers.totalFriends?.sales?.totalFormatted,
        friendsSalesPercent: state.customers.totalFriends?.sales?.percent ?? 0,
        friendsSalesPercentFormatted: state.customers.totalFriends?.sales?.percentFormatted,

        friendsAvgTicketTotal: state.customers.totalFriends?.averageTicket?.total ?? 0,
        friendsAvgTicketTotalFormatted: state.customers.totalFriends?.averageTicket?.totalFormatted,

        advocatesInvoicesTotal: state.customers.totalAdvocates?.invoices?.total ?? 0,
        advocatesInvoicesTotalFormatted: state.customers.totalAdvocates?.invoices?.totalFormatted,
        advocatesInvoicesPercent: state.customers.totalAdvocates?.invoices?.percent ?? 0,
        advocatesInvoicesPercentFormatted: state.customers.totalAdvocates?.invoices?.percentFormatted,

        advocatesSalesTotal: state.customers.totalAdvocates?.sales?.total ?? 0,
        advocatesSalesTotalFormatted: state.customers.totalAdvocates?.sales?.totalFormatted,
        advocatesSalesPercent: state.customers.totalAdvocates?.sales?.percent ?? 0,
        advocatesSalesPercentFormatted: state.customers.totalAdvocates?.sales?.percentFormatted,

        advocatesAvgTicketTotal: state.customers.totalAdvocates?.averageTicket?.total ?? 0,
        advocatesAvgTicketTotalFormatted: state.customers.totalAdvocates?.averageTicket?.totalFormatted,

        totalsInvoicesTotal: state.customers.grandTotals?.invoices?.total ?? 0,
        totalsInvoicesTotalFormatted: state.customers.grandTotals?.invoices?.totalFormatted,
        totalsInvoicesPercent: state.customers.grandTotals?.invoices?.percent ?? 0,
        totalsInvoicesPercentFormatted: state.customers.grandTotals?.invoices?.percentFormatted,

        totalsSalesTotal: state.customers.grandTotals?.sales?.total ?? 0,
        totalsSalesTotalFormatted: state.customers.grandTotals?.sales?.totalFormatted,
        totalsSalesPercent: state.customers.grandTotals?.sales?.percent ?? 0,
        totalsSalesPercentFormatted: state.customers.grandTotals?.sales?.percentFormatted
      }
    }
  }

  @Selector()
  static listResponse(state: CustomersStateModel) {
    return state.listResponse
  }

  @Selector()
  static communication(state: CustomersStateModel) {
    return state.communication
  }

  @Selector()
  static communicationHistories(state: CustomersStateModel) {
    return state.communicationHistories?.map((el) => ({
      ...el,
      statusIcon: {
        icon:
          el.status === CommunicationStatus.NotAnswered
            ? 'phone-call-hang-up'
            : el.status === CommunicationStatus.VoiceMessage
              ? 'message'
              : 'phone',
        color:
          el.status === CommunicationStatus.NotAnswered
            ? '#D32F2F'
            : el.status === CommunicationStatus.VoiceMessage
              ? '#4B5B63'
              : '#4CAF50'
      }
    }))
  }

  @Selector()
  static customer(state: CustomersStateModel) {
    return state.customer
  }

  @Selector()
  static customersInRadius(state: CustomersStateModel) {
    return state.customersInRadius
  }

  @Selector()
  static tags(state: CustomersStateModel) {
    return state.tags
  }

  @Selector()
  static invoicesResponse(state: CustomersStateModel) {
    return state.invoicesResponse
  }

  @Selector()
  static communicationsList(state: CustomersStateModel) {
    return state.communicationsList
  }

  @Selector()
  static agents(state: CustomersStateModel) {
    return state.agents ?? []
  }

  @Selector()
  static communicationsType(
    state: CustomersStateModel
  ): ICommunicationsTypeExtendedResponse | undefined {
    if (!state.communicationsType) {
      return
    }
    return {
      CommunicationTypes: state.communicationsType.contactCommunicationTypes.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,
        emailAndPhonePercent: el.emailAndPhone?.totalPercent ?? 0,
        emailAndPhoneTotal: el.emailAndPhone?.total ?? 0,
        emailPercent: el.email?.totalPercent ?? 0,
        emailTotal: el.email?.total ?? 0,
        phonePercent: el.phone?.totalPercent ?? 0,
        phoneTotal: el.phone?.total ?? 0,
        totalsPercent: el.totals?.totalPercent ?? 0,
        totalsTotal: el.totals?.total ?? 0
      })),
      grandTotal: {
        emailAndPhonePercent: state.communicationsType.totalEmailAndPhone?.totalPercent ?? 0,
        emailAndPhoneTotal: state.communicationsType.totalEmailAndPhone?.total ?? 0,
        emailPercent: state.communicationsType.totalEmail?.totalPercent ?? 0,
        emailTotal: state.communicationsType.totalEmail?.total ?? 0,
        phonePercent: state.communicationsType.totalPhone?.totalPercent ?? 0,
        phoneTotal: state.communicationsType.totalPhone?.total ?? 0,
        totalsPercent: state.communicationsType.grandTotals?.totalPercent ?? 0,
        totalsTotal: state.communicationsType.grandTotals?.total ?? 0
      }
    }
  }

  @Selector()
  static deferredTypes(state: CustomersStateModel): IDeferredTypeExtendedResponse | undefined {
    if (!state.deferredTypes) {
      return
    }
    return {
      timePeriod: state.deferredTypes.timePeriod,
      deferredTypes: state.deferredTypes.deferredTypes.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,
        totalServices: el.totalServices,
        deferredConcernPercent: el.deferredConcern?.totalPercent ?? 0,
        deferredConcernTotal: el.deferredConcern?.total ?? 0,
        deferredInspectionPercent: el.deferredInspection?.totalPercent ?? 0,
        deferredInspectionTotal: el.deferredInspection?.total ?? 0,
        deferredServicesPercent: el.deferredServices?.totalPercent ?? 0,
        deferredServicesTotal: el.deferredServices?.total ?? 0,
        totalsPercent: el.totals?.totalPercent ?? 0,
        totalsTotal: el.totals?.total ?? 0
      })),
      grandTotal: {
        deferredConcernPercent: state.deferredTypes.totalDeferredConcerns?.totalPercent ?? 0,
        deferredConcernTotal: state.deferredTypes.totalDeferredConcerns?.total ?? 0,
        deferredInspectionPercent: state.deferredTypes.totalDeferredInspections?.totalPercent ?? 0,
        deferredInspectionTotal: state.deferredTypes.totalDeferredInspections?.total ?? 0,
        deferredServicesPercent: state.deferredTypes.totalDeferredServices?.totalPercent ?? 0,
        deferredServicesTotal: state.deferredTypes.totalDeferredServices?.total ?? 0,
        totalsPercent: state.deferredTypes.grandTotals?.totalPercent ?? 0,
        totalsTotal: state.deferredTypes.grandTotals?.total ?? 0
      }
    }
  }

  @Action(GetCustomersTypes)
  getCustomersTypes(ctx: StateContext<CustomersStateModel>, { payload }: GetCustomersTypes) {
    return this.customersService.getCustomersTypes(payload).pipe(
      tap((res) => {
        ctx.patchState({
          customers: res
        })
      })
    )
  }

  @Action(GetCustomersList)
  getCustomersList(ctx: StateContext<CustomersStateModel>, { payload }: GetCustomersList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.customersService.getCustomersList(payload).pipe(
      tap((res) => {
        ctx.patchState({
          listResponse: res
        })
      })
    )
  }

  @Action(GetCustomer)
  getCustomer(ctx: StateContext<CustomersStateModel>, { customerId, shopId }: GetCustomer) {
    return this.customersService.getCustomer(customerId, shopId).pipe(
      tap((res) => {
        ctx.patchState({
          customer: res?.contactInfo
        })
      })
    )
  }

  @Action(UpdateCustomer)
  updateCustomer(ctx: StateContext<CustomersStateModel>, { customerId, payload }: UpdateCustomer) {
    return this.customersService.updateCustomer(customerId, payload)
  }

  @Action(GetCustomersInRadius, { cancelUncompleted: true })
  GetCustomersInRadius(ctx: StateContext<CustomersStateModel>, { payload }: GetCustomersInRadius) {
    return this.customersService.getCustomersInRadius(payload).pipe(
      tap((res) => {
        ctx.patchState({
          customersInRadius: res
        })
      })
    )
  }

  @Action(GetCustomerTags)
  getCustomerTags(ctx: StateContext<CustomersStateModel>, { customerId, shopId }: GetCustomerTags) {
    return this.customersService.getCustomerTags(customerId, shopId).pipe(
      tap((res) => {
        ctx.patchState({
          tags: res?.tags
        })
      })
    )
  }

  @Action(AddCustomerTag)
  addCustomerTag(ctx: StateContext<CustomersStateModel>, { customerId, payload }: AddCustomerTag) {
    return this.customersService.addCustomerTag(customerId, payload)
  }

  @Action(DeleteCustomerTag)
  deleteCustomerTag(ctx: StateContext<CustomersStateModel>, { customerId, payload }: DeleteCustomerTag) {
    return this.customersService.deleteCustomerTag(customerId, payload)
  }

  @Action(GetCustomerInvoices)
  getCustomerInvoices(
    ctx: StateContext<CustomersStateModel>,
    { customerId, shopId }: GetCustomerInvoices
  ) {
    ctx.patchState({
      invoicesResponse: undefined
    })
    return this.customersService.getCustomerInvoices(customerId, shopId).pipe(
      tap((res) => {
        ctx.patchState({
          invoicesResponse: res
        })
      })
    )
  }

  @Action(GetCommunicationHistory)
  getCommunicationHistory(
    ctx: StateContext<CustomersStateModel>,
    { customerId, shopId }: GetCommunicationHistory
  ) {
    return this.customersService.getCommunicationHistory(customerId, shopId).pipe(
      tap((res) => {
        ctx.patchState({
          communicationHistories: res?.items
        })
      })
    )
  }

  @Action(GetCommunication)
  getCommunication(
    ctx: StateContext<CustomersStateModel>,
    { customerId, shopId, id }: GetCommunication
  ) {
    return this.customersService.getCommunication(customerId, shopId, id).pipe(
      tap((res) => {
        ctx.patchState({
          communication: res
        })
      })
    )
  }

  @Action(AddCommunication)
  addCommunication(ctx: StateContext<CustomersStateModel>, { customerId, payload }: AddCommunication) {
    return this.customersService.addCommunication(customerId, payload)
  }

  @Action(UpdateCommunication)
  updateCommunication(
    ctx: StateContext<CustomersStateModel>,
    { customerId, payload }: UpdateCommunication
  ) {
    return this.customersService.updateCommunication(customerId, payload)
  }

  @Action(GetCommunicationsList)
  getCommunicationsList(ctx: StateContext<CustomersStateModel>, { payload }: GetCommunicationsList) {
    ctx.patchState({
      communicationsList: undefined
    })
    return this.customersService
      .getCommunicationsList({
        ...payload.filters,
        ...this.tableFilterService.createBaseRequestPayload(payload.tableInfo)
      })
      .pipe(
        tap((res) => {
          ctx.patchState({
            communicationsList: res
          })
        })
      )
  }

  @Action(GetAgents)
  getAgents(ctx: StateContext<CustomersStateModel>) {
    const agents = ctx.getState()?.agents
    if (agents) {
      return agents
    }
    return this.customersService.getAgents().pipe(
      tap((res) => {
        ctx.patchState({
          agents: res
        })
      })
    )
  }

  @Action(GetCommunicationType)
  getCommunicationType(ctx: StateContext<CustomersStateModel>, { payload }: GetCommunicationType) {
    return this.customersService.getCommunicationType(payload).pipe(
      tap((res) => {
        ctx.patchState({
          communicationsType: res
        })
      })
    )
  }

  @Action(GetDeferredTypes)
  getDeferredTypes(ctx: StateContext<CustomersStateModel>, { payload }: GetDeferredTypes) {
    return this.customersService.getDeferredTypes(payload).pipe(
      tap((res) => {
        ctx.patchState({
          deferredTypes: res
        })
      })
    )
  }
}
