<div
  class="w-full md:px-[56px] px-2xl py-lg flex flex-col md:flex-row justify-between items-center bg-gray-100 rounded-base {{
    containerClass
  }}"
  *ngIf="info"
>
  <div
    class="flex-1 flex flex-col items-center text-center md:text-left md:items-start mb-2xl md:mb-0 text-txt text-lg leading-lg"
  >
    <div #titleSlot>
      <ng-content select="[slot=title]"></ng-content>
    </div>
    <span *ngIf="!titleSlot.children.length">{{ info.title }}</span>
    <span
      *ngIf="info.subTitle"
      [ngClass]="{ 'mt-lg': allMediaTitleMargin, 'md:mt-lg': !allMediaTitleMargin }"
    >
      {{ info.subTitle }}
    </span>
  </div>
  <div class="flex-1 flex justify-end">
    <img class="no-report-image" [src]="info.imagePath" alt="No Report Image" />
  </div>
</div>
