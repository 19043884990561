<!-- Just small media: This div used to reseve the space for header even with absolute elements -->
<div class="h-[40px] lg:hidden"></div>

<!-- Main content -->
<div class="border-b border-gray-100">
  <div
    class="fixed top-0 z-[250] lg:relative w-full bg-white py-sm mx-auto px-lg lg:px-2xl flex items-center justify-center lg:block border-b border-gray-100 lg:border-b-0"
  >
    <div
      class="flex lg:hidden absolute left-2xl cursor-pointer top-base text-txt"
      (click)="showMenu = !showMenu"
      [ngClass]="{ 'top-base': !showMenu }"
    >
      <svg-icon
        [key]="showMenu ? 'close-light' : 'menu'"
        [ngClass]="{ '!text-xs': showMenu, '!text-lg': !showMenu }"
      ></svg-icon>
    </div>

    <div class="h-[32px] hidden lg:flex items-center">
      <div
        (click)="showLargeMenu = !showLargeMenu; showLargeMenuChanged.emit(showLargeMenu)"
        class="h-full w-[81px] group cursor-pointer flex items-center"
        *ngIf="showLargeMenu"
      >
        <img class="group-hover:hidden w-full" src="assets/images/icons/new-big-logo.svg" alt="Logo" />
        <div class="hidden group-hover:flex w-full h-full items-center text-primary">
          <svg-icon key="sidebar-middle" class="!text-lg"></svg-icon>
        </div>
      </div>
      <div
        (click)="showLargeMenu = !showLargeMenu; showLargeMenuChanged.emit(showLargeMenu)"
        *ngIf="!showLargeMenu"
        class="h-full w-[81px] flex items-center text-primary cursor-pointer"
      >
        <svg-icon key="sidebar-left" class="!text-lg"></svg-icon>
      </div>
    </div>

    <img class="lg:hidden h-2xl cursor-pointer" src="assets/images/icons/new-logo.svg" alt="Logo" />

    <div class="absolute top-base right-2xl">
      <button
        (click)="menuTrigger.openMenu(); showMenu = false"
        class="relative flex items-center justify-end menu-buttons"
      >
        <div
          class="pr-base hidden lg:block text-base leading-base text-txt"
          *ngIf="currentUser$ | async; let currentUser"
        >
          {{ currentUser.firstName }} {{ currentUser.lastName }}
        </div>
        <div class="flex text-txt">
          <svg-icon key="settings" class="!text-lg"></svg-icon>
        </div>
      </button>
      <div [matMenuTriggerFor]="profileMenu" #menuTrigger="matMenuTrigger"></div>
      <mat-menu
        #profileMenu="matMenu"
        [hasBackdrop]="true"
        backdropClass="profile-menu-backdrop"
        class="profile-mat-menu"
      >
        <ng-template matMenuContent>
          <div
            class="block lg:hidden text-base leading-base text-txt font-semibold border-b border-gray-100 px-base py-sm"
            *ngIf="currentUser$ | async; let currentUser"
          >
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </div>
          <div class="flex flex-col menu-buttons">
            <a
              *ngFor="let item of profileMenuRoutes"
              class="profile-menu-item"
              [routerLink]="item.path"
              routerLinkActive="text-primary"
              (click)="hideCollapsableMenu()"
            >
              <div class="flex text-txt">
                <svg-icon class="mr-base !text-sm" [key]="item.icon"></svg-icon>
              </div>
              <span>{{ item.label }}</span>
            </a>

            <a class="profile-menu-item !pb-sm border-t border-gray-100" (click)="logout()">
              <div class="flex text-txt">
                <svg-icon class="mr-base !text-sm" key="exit"></svg-icon>
              </div>
              <span>Log Out</span>
            </a>
          </div>
        </ng-template>
      </mat-menu>
    </div>
  </div>
</div>

<!-- Just small media: Closable menu  -->
<div
  class="closable-menu w-full fixed bg-white max-h-[65%] beautyScroll overflow-y-auto"
  *ngIf="showMenu"
>
  <ng-container *ngFor="let item of mainMenuRoutes; let i = index">
    <ng-container
      *ngTemplateOutlet="expandableMenuItem; context: { item: item, index: i }"
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="bookmarks && bookmarks.length > 0">
    <ng-container
      *ngTemplateOutlet="
        menuItem;
        context: {
          item: {
            path: '',
            icon: 'bookmark',
            label: 'Bookmarks',
            subMenus: []
          }
        }
      "
    ></ng-container>
    <ng-container *ngFor="let item of bookmarks">
      <ng-container *ngTemplateOutlet="bookmarkMenuItem; context: { item: item }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<!-- Just small media: Backdrop -->
<div
  *ngIf="showMenu"
  (click)="showMenu = false"
  class="lg:hidden fixed top-0 left-0 right-0 bottom-0 bg-txt/50 z-[200]"
></div>

<!-- Expandable Menu Item Template -->
<ng-template #expandableMenuItem let-index="index" let-item="item">
  <div>
    <ng-container *ngTemplateOutlet="menuItem; context: { item: item }"></ng-container>
  </div>
  <ng-container>
    <ng-container *ngFor="let sumMenu of item.subMenus">
      <ng-container *ngTemplateOutlet="menuItem; context: { item: sumMenu }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Item Template -->
<ng-template #menuItem let-item="item">
  <a
    *ngIf="!item.hidden"
    class="closable-menu-item"
    [routerLink]="item.path"
    [queryParams]="item.queryParams"
    (click)="hideCollapsableMenu()"
  >
    <div class="flex text-txt">
      <svg-icon class="mr-base !text-sm" [key]="item.icon"></svg-icon>
    </div>
    <span>{{ item.label }}</span>
    <div *ngIf="item.subMenus" class="ml-base flex-1 h-[1px] bg-gray-200"></div>
  </a>
</ng-template>

<!-- Bookmark Item Template -->
<ng-template #bookmarkMenuItem let-item="item">
  <a
    class="closable-menu-item"
    [routerLink]="item.path"
    [queryParams]="item.queryParams"
    target="_blank"
    (click)="hideCollapsableMenu()"
  >
    <div class="flex">
      <svg-icon class="!text-sm" [key]="item.icon"></svg-icon>
    </div>
    <span class="pl-base flex-1">{{ item.label }}</span>
    <div (click)="deleteBookmark($event, item.extraInfo)" class="pl-base -mr-[3px] flex items-center">
      <div class="flex text-txt-disabled">
        <svg-icon
          *ngIf="deletingBookmarkId !== item.extraInfo"
          key="close-circle"
          class="!text-sm"
        ></svg-icon>
      </div>
      <lla-loading-spinner *ngIf="deletingBookmarkId === item.extraInfo"></lla-loading-spinner>
    </div>
  </a>
</ng-template>
