<div *ngIf="!standalone" class="p-lg md:p-base pb-2xl text-base leading-base font-semibold">
  Select dates
</div>

<div
  class="flex-1 flex flex-col md:flex-row md:border-t md:border-gray-100"
  [ngClass]="{ 'border-b': standalone }"
>
  <div *ngIf="!hideAllPeriods" class="pills-container" [ngClass]="{ '!pl-0': standalone }">
    <lla-pill
      class="md:hidden"
      extraClass="w-max min-w-max"
      *ngFor="let item of periodTypeButtonsInfo"
      [selected]="value?.periodType === item.value"
      [label]="item.label"
      size="m"
      (click)="setDateByPeriodType(item)"
    >
    </lla-pill>

    <lla-pill
      class="hidden md:block w-full"
      extraClass="w-full min-w-max"
      *ngFor="let item of periodTypeButtonsInfo"
      [selected]="value?.periodType === item.value"
      [label]="item.label"
      size="s"
      (click)="setDateByPeriodType(item)"
    >
    </lla-pill>
  </div>

  <div class="flex-1 flex flex-col">
    <form
      [formGroup]="rangeForm"
      class="flex items-center mb-sm md:mb-xs -mt-sm pt-2xl md:pt-lg px-xl md:px-base md:pb-0"
      [ngClass]="{ '!px-0 md:!px-base': standalone }"
    >
      <lla-input
        class="flex-1"
        size="small"
        extraInputClass="!text-base"
        controlName="fromText"
        label="Start date"
        type="text"
        (inputFocus)="setCalendarActiveDate(rangeForm.get('from')?.value)"
        (inputFocusOut)="setFromText(fromControl?.value)"
      ></lla-input>
      <div class="px-xs text-txt-secondary">-</div>
      <lla-input
        class="flex-1"
        size="small"
        extraInputClass="!text-base"
        controlName="toText"
        label="End date"
        type="text"
        (inputFocus)="setCalendarActiveDate(rangeForm.get('to')?.value)"
        (inputFocusOut)="setToText(toControl?.value)"
      ></lla-input>
    </form>

    <div class="flex-1 px-xs pb-0" [ngClass]="{ '!pb-0 !px-0': standalone }">
      <mat-calendar
        #calendar
        class="mg-date-range-picker-calendar"
        [selected]="selectedDateRange"
        (selectedChange)="onSelectedDateChange($event)"
        [maxDate]="calendarMaxDate"
        [minDate]="minDate ?? undefined"
      ></mat-calendar>
    </div>
  </div>
</div>

<div *ngIf="!standalone" class="flex items-center justify-end p-base border-t border-ui-separator">
  <lla-button class="mr-2xl" variant="ghost" size="medium-shrink" (click)="canceld.emit()">
    Cancel
  </lla-button>
  <lla-button
    variant="ghost"
    size="medium-shrink"
    extraBtnClass="!text-primary"
    (click)="fireValueChangedEvent()"
  >
    Apply
  </lla-button>
</div>
