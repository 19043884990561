import { Sort } from '@angular/material/sort'
import { ISelectItem } from '@lla-platform/core/core-ui'

// #region Table Interfaces

export enum New_AggregatorType {
  And = 'And',
  Or = 'Or'
}

export enum New_TableOperator {
  'LessThan' = 'LessThan',
  'LessThanOrEqual' = 'LessThanOrEqual',
  'Equals' = 'Equals',
  'NotEquals' = 'NotEquals',
  'GreaterThanOrEqual' = 'GreaterThanOrEqual',
  'GreaterThan' = 'GreaterThan',
  'Contains' = 'Contains',
  'StartsWith' = 'StartsWith',
  'EndsWith' = 'EndsWith',
  'Period' = 'Period'
}

export enum New_TableColumnType {
  text = 'text',
  number = 'number',
  radio = 'radio',
  checkbox = 'checkbox',
  date = 'date'
}

export interface INew_TableFilterRule {
  operator: New_TableOperator
  value: any
  extraValue?: any
}

export interface INew_TableFilter {
  key: string
  label?: string
  columnType?: New_TableColumnType
  aggregator?: New_AggregatorType
  items?: INew_TableFilterRule[]
  listItems?: ISelectItem[]
  searchable?: boolean
  showCountThreshold?: number
  showCountLabel?: string
  hideSorting?: boolean
  disable?: boolean
}

export interface INew_TableFIlterNotifier {
  key?: string
  filter?: INew_TableFilter
  sort?: Sort | null
  shouldDelete?: boolean
}

export type New_TableFilters = { [key: string]: INew_TableFilter }

export interface INew_TableAction {
  sort?: Sort | null
  filters?: New_TableFilters
  search?: string
  page: number
}

export interface INew_TableActionRequest {
  sorts?: Sort[]
  filters?: INew_TableFilter[]
  search?: string
}

//#endregion

// #region Request Interfaces

export interface INew_TableFilterRequest {
  [key: string]: INew_TableFilter
}

export interface INew_PaginationFilteredOrderBy {
  property: string
  direction: 'Ascending' | 'Descending'
}

export interface INew_PaginationBaseRequest {
  orderBy?: INew_PaginationFilteredOrderBy[]
  top: number
  skip: number
}

export interface INew_PaginationFilteredRequest extends INew_PaginationBaseRequest {
  filters: INew_TableFilterRequest
}

export interface INew_PaginationFilteredResponse {
  count: number
}
//#endregion
