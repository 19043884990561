import { ITimePeriod, ITimePeriodRequest, IUrlFilters } from '@lla-platform/core/core-data-access'

export interface ICustomerRequest extends ITimePeriodRequest {
  locationIds?: string[]
}

export interface ICustomerTypeData {
  total: number
  totalFormatted: string
  percent: number
  percentFormatted: string
}

export interface ICustomerTypeInfo {
  invoices: ICustomerTypeData
  sales: ICustomerTypeData
  averageTicket: ICustomerTypeData
}

export interface ICustomerType {
  locationId: string
  locationName: string
  new: ICustomerTypeInfo
  friends: ICustomerTypeInfo
  advocates: ICustomerTypeInfo
  totals: ICustomerTypeInfo
}

export interface ICustomerTypeExtended extends IUrlFilters {
  locationId?: string
  locationName?: string

  newInvoicesTotal: number
  newInvoicesTotalFormatted: string
  newInvoicesPercent: number
  newInvoicesPercentFormatted: string

  newSalesTotal: number
  newSalesTotalFormatted: string
  newSalesPercent: number
  newSalesPercentFormatted: string

  newAvgTicketTotal: number
  newAvgTicketTotalFormatted: string

  friendsInvoicesTotal: number
  friendsInvoicesTotalFormatted: string
  friendsInvoicesPercent: number
  friendsInvoicesPercentFormatted: string

  friendsSalesTotal: number
  friendsSalesTotalFormatted: string
  friendsSalesPercent: number
  friendsSalesPercentFormatted: string

  friendsAvgTicketTotal: number
  friendsAvgTicketTotalFormatted: string

  advocatesInvoicesTotal: number
  advocatesInvoicesTotalFormatted: string
  advocatesInvoicesPercent: number
  advocatesInvoicesPercentFormatted: string

  advocatesSalesTotal: number
  advocatesSalesTotalFormatted: string
  advocatesSalesPercent: number
  advocatesSalesPercentFormatted: string

  advocatesAvgTicketTotal: number
  advocatesAvgTicketTotalFormatted: string

  totalsInvoicesTotal: number
  totalsInvoicesTotalFormatted: string
  totalsInvoicesPercent: number
  totalsInvoicesPercentFormatted: string

  totalsSalesTotal: number
  totalsSalesTotalFormatted: string
  totalsSalesPercent: number
  totalsSalesPercentFormatted: string
}

export interface ICustomersTypeResponse {
  timePeriod: ITimePeriod
  contactTypes: ICustomerType[]
  totalNew: ICustomerTypeInfo
  totalFriends: ICustomerTypeInfo
  totalAdvocates: ICustomerTypeInfo
  grandTotals: ICustomerTypeInfo
}

export interface ICustomersTypeExtendedResponse {
  timePeriod: ITimePeriod
  customerTypes: ICustomerTypeExtended[]
  grandTotal: ICustomerTypeExtended
}

export interface ICustomerUpdateRequest {
  contactLocationId: string
  noCalls: boolean
}

export interface ICustomerGetItem {
  contactId: string
  locationId: string
  firstName: string
  lastName: string
  companyName: string
  phone: string
  email: string
  locationName: string
  noCalls: boolean
}

export interface ICustomerGetResponse {
  contactInfo: ICustomerGetItem
}
