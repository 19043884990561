import {
  ContactType,
  IAdvancedTable,
  INew_TableAction,
  IPaginationFilteredResponse,
  ITimePeriodRequest
} from '@lla-platform/core/core-data-access'
import { TagType } from '../enums/tag-type.enum'

export interface ICustomersListInfo {
  tableAction: INew_TableAction
  filters?: ICustomersListRequest
}

export interface ICustomersListRequest {
  period?: ITimePeriodRequest
  lastInvoice?: boolean
  name?: string
  corporateContact?: boolean
  invoiceMin?: number
  invoiceMax?: number
  contactType?: ContactType[]
  tags?: TagType[]
  locationIds?: string[]
}

export interface ICustomersListItem extends IAdvancedTable {
  contactId: string
  locationId: string
  firstName: string
  lastName: string
  phone: string
  email: string
  companyName: string
  locationName: string
  invoicesCount: number
  totalWithoutTax: number
  formattedTotalWithoutTax: string
  totalWithTax: number
  formattedTotalWithTax: string
  lastInvoiceDate: Date
}

export interface ICustomersListResponse extends IPaginationFilteredResponse {
  contacts: ICustomersListItem[]
}
