import { Component, Input } from '@angular/core'

export type PillSize = 'm' | 's'

@Component({
  selector: 'lla-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent {
  @Input() label = ''
  @Input() extraClass = ''
  @Input() selected = false
  @Input() hasHoverEffect = true
  @Input() size: PillSize
}
