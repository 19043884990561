<div class="modal-container relative p-xl">
  <div class="flex flex-col items-center text-center">
    <div class="flex text-info">
      <svg-icon key="warning-diamond" class="!text-2xl"></svg-icon>
    </div>
    <div class="mt-2xl text-base leading-base font-semibold">Switch to Simple Filters?</div>
    <div class="mt-sm text-sm leading-sm">
      This action will remove the advanced filters. Do you want to continue?
    </div>
    <div class="w-full flex flex-col md:flex-row-reverse items-center mt-xl">
      <lla-button
        class="md:flex-1 md:ml-base w-full md:w-auto"
        type="button"
        size="small"
        (click)="dialogRef.close(true)"
      >
        Yes
      </lla-button>
      <lla-button
        class="md:flex-1 w-full md:w-auto mt-base md:mt-0"
        size="small"
        variant="secondary"
        type="button"
        extraBtnClass="!px-[10px] !text-base"
        (click)="dialogRef.close(false)"
      >
        Cancel
      </lla-button>
    </div>
  </div>
</div>
