import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { ISelectItem } from '@lla-platform/core/core-ui'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnChanges {
  @Input() selectedItem?: any
  @Input() placeHolder: string
  @Input() disable = false
  @Input() fullWidth = false
  @Input() items: ISelectItem[] = []

  @Output() selectedItemChanged = new EventEmitter<any>()

  selectedItemLabel?: string

  ngOnChanges(changes: SimpleChanges): void {
    const itemValue = changes['selectedItem']?.currentValue
    if (itemValue) {
      this.selectedItemLabel = this.items.find((el) => el.value === itemValue)?.label
    }
  }
}
