import { Injectable } from '@angular/core'
import { HttpService, ITimePeriodRequest } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { ICallReportResponse } from '../interfaces/calls-report.interface'
import { REPORTS_API_URL } from './reports-api-routes'
import { DateService } from '@lla-platform/core/core-util'
import { ISalesReportResponse } from '../interfaces/sales-report.interface'

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private http: HttpService, private dateService: DateService) {}

  getCallsReport(payload: ITimePeriodRequest): Observable<ICallReportResponse> {
    const { from, to } = payload
    return this.http.post(REPORTS_API_URL.callsReport, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getSalesReport(): Observable<ISalesReportResponse> {
    return this.http.get(REPORTS_API_URL.salesReport)
  }
}
