import { ISelectItem } from '@lla-platform/core/core-ui'
import moment from 'moment'

export type CalendarMaxDate = 'Today' | 'Yesterday' | 'EndOFLastWeek'

export enum PeriodType {
  Today = 'Today',
  Yesterday = 'Yesterday',
  PreviousWeek = 'PreviousWeek',
  PreviousMonth = 'PreviousMonth',
  WeekToDate = 'WeekToDate',
  MonthToDate = 'MonthToDate',
  SixMonthToDate = 'SixMonthToDate',
  YearToDate = 'YearToDate',
  Custom = 'Custom'
}

export const PeriodTypeToLabel: { [key: string]: string } = {
  Today: 'Today',
  Yesterday: 'Yesterday',
  PreviousWeek: 'Last Week',
  PreviousMonth: 'Last Month',
  WeekToDate: 'Week To Date',
  MonthToDate: 'Month To Date',
  SixMonthToDate: 'Last 6 Months',
  YearToDate: 'Year To Date',
  Custom: 'Custom'
}

export const PeriodTypeSelectItem: ISelectItem[] = [
  { label: 'Today', value: PeriodType.Today },
  { label: 'Yesterday', value: PeriodType.Yesterday },
  { label: 'Last Week', value: PeriodType.PreviousWeek },
  { label: 'Last Month', value: PeriodType.PreviousMonth },
  { label: 'Week To Date', value: PeriodType.WeekToDate },
  { label: 'Month To Date', value: PeriodType.MonthToDate },
  { label: 'Last 6 Months', value: PeriodType.SixMonthToDate },
  { label: 'Year To Date', value: PeriodType.YearToDate },
  { label: 'Custom', value: PeriodType.Custom }
]

export const PeriodTypeButtonsInfo = (): ISelectItem[] => [
  {
    label: 'Today',
    value: PeriodType.Today,
    extraInfo: {
      from: moment().utcOffset(0, true),
      to: moment().utcOffset(0, true)
    }
  },
  {
    label: 'Yesterday',
    value: PeriodType.Yesterday,
    extraInfo: {
      from: moment().subtract(1, 'day').utcOffset(0, true),
      to: moment().subtract(1, 'day').utcOffset(0, true)
    }
  },
  {
    label: 'Last Week',
    value: PeriodType.PreviousWeek,
    extraInfo: {
      from: moment().subtract(1, 'week').startOf('week').utcOffset(0, true),
      to: moment().subtract(1, 'week').endOf('week').utcOffset(0, true)
    }
  },
  {
    label: 'Last Month',
    value: PeriodType.PreviousMonth,
    extraInfo: {
      from: moment().subtract(1, 'month').startOf('month').utcOffset(0, true),
      to: moment().subtract(1, 'month').endOf('month').utcOffset(0, true)
    }
  },
  {
    label: 'Week To Date',
    value: PeriodType.WeekToDate,
    extraInfo: {
      from: moment().startOf('week').utcOffset(0, true),
      to: moment().utcOffset(0, true)
    }
  },
  {
    label: 'Month To Date',
    value: PeriodType.MonthToDate,
    extraInfo: {
      from: moment().startOf('month').utcOffset(0, true),
      to: moment().utcOffset(0, true)
    }
  },
  {
    label: 'Last 6 Months',
    value: PeriodType.SixMonthToDate,
    extraInfo: {
      from: moment().add(-5, 'month').startOf('month').utcOffset(0, true),
      to: moment().utcOffset(0, true)
    }
  },
  {
    label: 'Year To Date',
    value: PeriodType.YearToDate,
    extraInfo: {
      from: moment().startOf('year').utcOffset(0, true),
      to: moment().utcOffset(0, true)
    }
  },
  { label: 'Custom', value: PeriodType.Custom }
]

export const TodayString = () => moment().utcOffset(0, true).format()
export const YesterdayString = () => moment().subtract(1, 'day').utcOffset(0, true).format()
export const EndOFLastWeekString = () =>
  moment().subtract(1, 'week').endOf('week').startOf('day').utcOffset(0, true).utc().format()
export const MinDateString = moment({ year: 2021 }).startOf('year').utcOffset(0, true).format()
