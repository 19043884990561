<lla-button-group
  class="hidden md:block pt-base lg:pt-0 w-full md:w-auto"
  containerClass="w-full md:w-fit"
  itemClass="flex-auto sm:flex-1 md:flex-auto text-center"
  size="small"
  [buttons]="periodTypeButtonsInfo()"
  (selectedButtonChanged)="periodChanged.emit($event)"
  [selectedButtonValue]="selectedPeriod"
></lla-button-group>

<form [formGroup]="form" class="md:hidden w-full mt-base">
  <lla-input
    class="w-full"
    controlName="selectedPeriod"
    type="select"
    [icon]="'arrow-down'"
    iconSize="15px"
    label="Select Time Period"
    size="small"
    [items]="periodTypeSelectItem"
  ></lla-input>
</form>
