<div class="flex items-center {{ extraClass }}" (click)="changed.emit(!value)">
  <input
    type="checkbox"
    class="pointer-events-none form-checkbox mr-sm rounded-xs text-primary focus:outline-none focus:ring-transparent focus:shadow-none"
    style="box-shadow: none !important"
    [ngStyle]="{ width: size + 'px', height: size + 'px' }"
    [checked]="value"
    [disabled]="disabled"
  />
  <div
    class="text-sm leading-sm text-txt cursor-pointer {{ extraLabelClass }}"
    [ngClass]="{ '!text-txt-disabled': disabled }"
    [ngStyle]="{ fontSize: size + 'px' }"
  >
    {{ label }}
  </div>
</div>
