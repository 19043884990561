<div class="w-full flex justify-between py-sm px-base border border-gray-300 rounded-base">
  <div #searchContainer class="flex-1 max-w-[calc(100%-16px)] flex items-center gap-sm">
    @if (shouldGroup) {
    <div>
      <div
        (click)="
          appliedSearchesVisible = !appliedSearchesVisible;
          appliedSearchesVisible ? menuTrigger.openMenu() : undefined
        "
        class="flex items-center py-[3px] pl-base pr-xs rounded-sm bg-primary cursor-pointer"
      >
        <div class="mr-base text-xs leading-xs font-semibold text-white">
          {{ searchItems.length }} searches applied
        </div>
        <div class="p-xs flex text-white cursor-pointer">
          <svg-icon
            [key]="appliedSearchesVisible ? 'arrow-up' : 'arrow-down'"
            class="!text-2xs"
          ></svg-icon>
        </div>
      </div>
      <div [matMenuTriggerFor]="searchItemsMenu" #menuTrigger="matMenuTrigger"></div>
    </div>
    } @else {
    <div
      class="max-w-full flex items-center py-[3px] pl-base pr-xs rounded-sm border border-txt-secondary"
      *ngFor="let item of searchItems; let i = index"
    >
      <div class="mr-xs truncate text-xs leading-xs font-semibold text-txt-secondary">
        {{ item }}
      </div>
      <div (click)="removePill(i)" class="p-xs flex text-txt-secondary cursor-pointer">
        <svg-icon key="close" class="!text-2xs"></svg-icon>
      </div>
    </div>
    }

    <input
      autofocus
      (keyup.enter)="addNewPill()"
      (keydown.delete)="removeLastPill()"
      (keydown.backspace)="removeLastPill()"
      [(ngModel)]="searchTerm"
      type="text"
      class="flex-1 min-w-[100px] outline-none border-none text-sm text-txt"
      placeholder="Search..."
    />
  </div>

  <div
    class="flex items-center h-2xl text-txt-secondary"
    [ngClass]="{ 'cursor-pointer': searchItems.length > 0 }"
    (click)="removeAllPills()"
  >
    <svg-icon
      [key]="searchItems.length > 0 ? 'close' : 'search'"
      [fontSize]="searchItems.length > 0 ? '11px' : '15px'"
    ></svg-icon>
  </div>
</div>

<div #pillsContainer class="absolute left-0 top-0 w-fit flex items-center h-0 overflow-hidden">
  <div class="flex items-center py-[3px] pl-base pr-xs" *ngFor="let item of searchItems; let i = index">
    <div class="mr-xs text-xs leading-xs font-semibold">{{ item }}</div>
    <div class="p-xs flex">
      <svg-icon key="close" class="!text-2xs"></svg-icon>
    </div>
  </div>
</div>

<mat-menu
  #searchItemsMenu="matMenu"
  [hasBackdrop]="true"
  class="mg-search-pills-menu"
  (close)="appliedSearchesVisible = false"
>
  <ng-template matMenuContent>
    <div
      class="p-lg flex items-center flex-wrap gap-sm"
      (click)="$event.preventDefault(); $event.stopPropagation()"
    >
      <div
        class="max-w-full flex items-center py-[3px] pl-base pr-xs rounded-sm border border-txt-secondary"
        *ngFor="let item of searchItems; let i = index"
      >
        <div class="mr-xs truncate text-xs leading-xs font-semibold text-txt-secondary">
          {{ item }}
        </div>
        <div (click)="removePill(i)" class="p-xs flex text-txt-secondary cursor-pointer">
          <svg-icon key="close" class="!text-2xs"></svg-icon>
        </div>
      </div>
    </div>
  </ng-template>
</mat-menu>
