import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { PillSize } from '../pill/pill.component'
import { ISelectItem } from '@lla-platform/core/core-ui'

@UntilDestroy()
@Component({
  selector: 'lla-items-selector',
  templateUrl: './items-selector.component.html',
  styleUrls: ['./items-selector.component.scss']
})
export class ItemsSelectorComponent implements OnInit, OnChanges {
  @Input() label: string
  @Input() allItems: ISelectItem[] = []
  @Input() selectedId: string
  @Input() pillSize: PillSize = 'm'
  @Output() selectedIdChanged = new EventEmitter<string>()
  @Output() canceld = new EventEmitter()

  selectedItems: ISelectItem[] = []

  form: UntypedFormGroup
  searchInputId = 'location_selector_search_input'

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      searchText: [null]
    })

    this.form
      .get('searchText')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this.filterItems(value)
      })
  }

  ngOnChanges(): void {
    this.filterItems()
  }

  public setFocusOnSearchInput() {
    setTimeout(() => {
      document.getElementById(this.searchInputId)?.focus()
    })
  }

  filterItems(value?: string) {
    const trimValue = value?.toLowerCase().trim() ?? ''
    if (trimValue === '') {
      this.selectedItems = [...this.allItems]
    } else {
      this.selectedItems = this.allItems.filter((el) => el.label.toLowerCase().includes(trimValue))
    }
  }
}
