<div class="pt-2xl px-2xl pb-sm md:pb-2xl lg:px-2xl lg:py-[22px]">
  <lla-page-title [title]="title">
    <div
      *ngIf="subtitleInfo"
      slot="subTitle"
      class="flex items-center"
      [ngClass]="{ 'cursor-pointer': subtitleInfo.link }"
      [routerLink]="subtitleInfo.link"
    >
      <svg-icon *ngIf="subtitleInfo.icon" class="!text-xs" [key]="subtitleInfo.icon"></svg-icon>
      <span [ngClass]="{ 'ml-base': subtitleInfo.icon }"> {{ subtitleInfo.text }} </span>
    </div>

    <div class="flex items-center justify-end flex-1">
      <div
        class="mr-2xl text-primary flex items-center cursor-pointer"
        *ngIf="!advancedFilter || numberOfAppliedFilters > 0"
        (click)="showAppliedFiltersChanged()"
      >
        <svg-icon key="filters" class="!text-sm"></svg-icon>
        <span class="px-sm text-sm leading-sm">Show filters</span>
        <div
          class="text-white bg-primary text-2xs leading-2xs font-semibold rounded-full w-lg h-lg flex items-center justify-center"
          *ngIf="numberOfAppliedFilters > 0"
        >
          {{ numberOfAppliedFilters }}
        </div>
      </div>
      <div
        class="mr-2xl py-sm flex items-center cursor-pointer text-primary"
        [matMenuTriggerFor]="showHideColumnsMenu"
        #showHideColumnsMenuTrigger="matMenuTrigger"
      >
        <svg-icon key="columns" class="!text-sm"></svg-icon>
        <span class="ml-sm text-sm leading-sm">Columns</span>
      </div>

      <div (click)="showAddBookmarkModal()" class="py-sm flex items-center cursor-pointer text-primary">
        <svg-icon key="bookmark" class="!text-sm"></svg-icon>
        <span class="mx-sm text-sm leading-sm">Add Bookmark</span>
      </div>
    </div>
  </lla-page-title>
</div>

<!-- Show/Hide Columns Menu -->
<mat-menu #showHideColumnsMenu="matMenu">
  <ng-template matMenuContent>
    <form
      [formGroup]="showHideColumnsForm"
      class="flex flex-col p-base"
      (click)="$event.stopPropagation()"
    >
      <lla-checkbox
        *ngFor="let filter of filtersList"
        [controlName]="filter.key"
        [label]="filter.label ?? ''"
      ></lla-checkbox>
    </form>
  </ng-template>
</mat-menu>
