<mat-form-field
  [appearance]="appearance"
  class="w-full lla-input"
  [ngClass]="{
    'lla-input-small': size === 'small',
    'lla-input-default': size === 'default'
  }"
>
  <mat-label
    *ngIf="label"
    class="text-txt-secondary tracking-normal text-base"
    [ngClass]="{
      'text-error': isFormInvalid()
    }"
  >
    <span class="inline-block lowercase first-letter:uppercase">{{ label }}</span>
  </mat-label>
  <span *ngIf="prefix" matPrefix>{{ prefix }} &nbsp;</span>

  <mat-date-range-input [formGroup]="rangeForm" [rangePicker]="picker" [max]="maxDate" [min]="minDate">
    <input
      matStartDate
      placeholder="Start date"
      formControlName="from"
      (focus)="picker.open()"
      (click)="picker.open()"
    />
    <input
      matEndDate
      placeholder="End date"
      formControlName="to"
      (focus)="picker.open()"
      (click)="picker.open()"
    />
  </mat-date-range-input>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <mat-progress-spinner *ngIf="loading" matSuffix [diameter]="16" mode="indeterminate">
  </mat-progress-spinner>
  <svg-icon
    matSuffix
    *ngIf="!loading && icon"
    [key]="icon"
    [color]="iconColor"
    [fontSize]="iconSize"
    (click)="iconClicked.emit($event)"
  ></svg-icon>

  <mat-error class="text-sm text-error tracking-normal mt-[3px]" *ngIf="isFormInvalid()">
    {{ handleErrorMessage() }}
  </mat-error>
</mat-form-field>
