import { Injectable } from '@angular/core'
import {
  HttpService,
  IAdvancedTableInfo,
  IExportFileResponse,
  INew_TableAction,
  IPaginationFilteredRequest,
  ITimePeriodRequest,
  TableActionsService,
  TableFilterService
} from '@lla-platform/core/core-data-access'
import { DateService } from '@lla-platform/core/core-util'
import { Observable } from 'rxjs'
import { IArInvoicesListResponse } from '../interfaces/ar-invoices-list.interface'
import { IArInvoiceRequest, IArInvoicesResponse } from '../interfaces/ar-invoices.interface'
import {
  IReceivableDetailedResponse,
  IUpdateReceivableRequest
} from '../interfaces/receivable.interface'
import {
  IReceivablesSummaryByShopRequest,
  IShopReceivableResponse
} from '../interfaces/shops-receivable.interface'
import { RECEIVABLE_API_URL } from './receivable-api-routes'
import { IInvoiceDetailResponse } from '../interfaces/invoice.interface'
import {
  IInvoiceGrossProfitRequest,
  IInvoiceGrossProfitResponse
} from '../interfaces/invoice-gross-profit.interface'
import { IGrossProfitListResponse } from '../interfaces/gross-profit-list.interface'
import { IGpPackageListResponse } from '../interfaces/gp-package-list.interface'
import {
  IGpPackageListRequest,
  IInvoiceGpPackageResponse
} from '../interfaces/invoice-gp-package.interface'
import {
  IPackageUnperformedRequest,
  IPackageUnperformedResponse
} from '../interfaces/package-unperformed.interface'

@Injectable({
  providedIn: 'root'
})
export class ReceivableService {
  constructor(
    private http: HttpService,
    private dateService: DateService,
    private tableFilterService: TableFilterService,
    private tableActionsService: TableActionsService
  ) {}

  getDetailedList(info: INew_TableAction): Observable<IReceivableDetailedResponse> {
    return this.http.post(
      RECEIVABLE_API_URL.detailedList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  exportReceivablesList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      RECEIVABLE_API_URL.exportReceivablesList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  getReceivablesSummaryByShop(
    payload: IReceivablesSummaryByShopRequest
  ): Observable<IShopReceivableResponse> {
    return this.http.post(RECEIVABLE_API_URL.receivablesSummaryByShop, payload)
  }

  updateReceivable(receivableId: string, payload: IUpdateReceivableRequest) {
    return this.http.put(RECEIVABLE_API_URL.base(receivableId), payload)
  }

  exportReceivablesSummaryByShop(
    payload: IReceivablesSummaryByShopRequest
  ): Observable<IExportFileResponse> {
    return this.http.post(RECEIVABLE_API_URL.exportReceivablesSummaryByShop, payload)
  }

  getArInvoicesSummary(payload: IArInvoiceRequest): Observable<IArInvoicesResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.arInvoicesSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportArInvoicesSummary(payload: ITimePeriodRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.exportArInvoicesSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getArInvoicesList(info: INew_TableAction): Observable<IArInvoicesListResponse> {
    return this.http.post(
      RECEIVABLE_API_URL.arInvoicesList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  exportArInvoicesList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      RECEIVABLE_API_URL.exportArInvoicesList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  getGrossProfitList(info: INew_TableAction): Observable<IGrossProfitListResponse> {
    return this.http.post(
      RECEIVABLE_API_URL.grossProfitList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  exportGrossProfitList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      RECEIVABLE_API_URL.exportGrossProfitList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  getGrossProfit(payload: IInvoiceGrossProfitRequest): Observable<IInvoiceGrossProfitResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.getGrossProfit, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportGrossProfit(payload: IInvoiceGrossProfitRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.exportGrossProfit, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getGpPackageList(info: INew_TableAction): Observable<IGpPackageListResponse> {
    return this.http.post(
      RECEIVABLE_API_URL.gpPackageList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  exportGpPackageList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      RECEIVABLE_API_URL.exportGpPackageList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  getGpPackage(payload: IGpPackageListRequest): Observable<IInvoiceGpPackageResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.getGpPackage, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportGpPackage(payload: IGpPackageListRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.exportGpPackage, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getReceivablesSummaryByAccount(
    payload: IReceivablesSummaryByShopRequest
  ): Observable<IShopReceivableResponse> {
    return this.http.post(RECEIVABLE_API_URL.receivablesSummaryByAccount, payload)
  }

  exportReceivablesSummaryByAccount(
    payload: IReceivablesSummaryByShopRequest
  ): Observable<IExportFileResponse> {
    return this.http.post(RECEIVABLE_API_URL.exportReceivablesSummaryByAccount, payload)
  }

  getInvoice(invoiceId: string): Observable<IInvoiceDetailResponse> {
    return this.http.get(RECEIVABLE_API_URL.getInvoice(invoiceId))
  }

  getArPaymentsSummary(payload: IArInvoiceRequest): Observable<IArInvoicesResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.arPaymentsSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportArPaymentsSummary(payload: IArInvoiceRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.exportArPaymentsSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getPackageUnperformed(payload: IPackageUnperformedRequest): Observable<IPackageUnperformedResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.getPackageUnperformed, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportPackageUnperformed(payload: IPackageUnperformedRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(RECEIVABLE_API_URL.exportPackageUnperformed, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }
}
