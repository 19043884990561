import { ISelectItem } from '@lla-platform/core/core-ui'

export enum ChapterCodeType {
  NotSet = 'NotSet',
  Service = 'Service',
  Inspection = 'Inspection',
  Concern = 'Concern',
  GeneralCredits = 'GeneralCredits',
  DeferredService = 'DeferredService',
  DeferredConcern = 'DeferredConcern',
  DeferredInspection = 'DeferredInspection'
}

export const ChapterCodeTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Service: 'Service',
  Inspection: 'Inspection',
  Concern: 'Concern',
  GeneralCredits: 'General Credits',
  DeferredService: 'Deferred Service',
  DeferredConcern: 'Deferred Concern',
  DeferredInspection: 'Deferred Inspection'
}

export const ChapterCodeTypeItems: ISelectItem[] = [
  {
    label: ChapterCodeTypeToLabel[ChapterCodeType.NotSet],
    value: ChapterCodeType.NotSet
  },
  {
    label: ChapterCodeTypeToLabel[ChapterCodeType.Service],
    value: ChapterCodeType.Service
  },
  {
    label: ChapterCodeTypeToLabel[ChapterCodeType.Inspection],
    value: ChapterCodeType.Inspection
  },
  {
    label: ChapterCodeTypeToLabel[ChapterCodeType.Concern],
    value: ChapterCodeType.Concern
  },
  {
    label: ChapterCodeTypeToLabel[ChapterCodeType.GeneralCredits],
    value: ChapterCodeType.GeneralCredits
  },
  {
    label: ChapterCodeTypeToLabel[ChapterCodeType.DeferredService],
    value: ChapterCodeType.DeferredService
  },
  {
    label: ChapterCodeTypeToLabel[ChapterCodeType.DeferredConcern],
    value: ChapterCodeType.DeferredConcern
  },
  {
    label: ChapterCodeTypeToLabel[ChapterCodeType.DeferredInspection],
    value: ChapterCodeType.DeferredInspection
  }
]
