import { ApplicationRoutes } from '../../../app-routes-names'

export const TargetsRoutes = {
  objectives: 'objectives',
  objectivesWithWeekNumber: 'objectives/:weekNumber',
  objectivesVsComp: 'objectives-vs-comp',
  locations: 'locations',
  regions: 'regions'
}

const { targets } = ApplicationRoutes

export const TargetsPaths = {
  objectives: `/${targets}/${TargetsRoutes.objectives}`,
  objectivesWithWeekNumber: (weekNumber: number) =>
    `/${targets}/${TargetsRoutes.objectives}/${weekNumber}`,
  objectivesVsComp: `/${targets}/${TargetsRoutes.objectivesVsComp}`,
  locations: `/${targets}/${TargetsRoutes.locations}`,
  regions: `/${targets}/${TargetsRoutes.regions}`
}
