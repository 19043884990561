<div class="mr-2xl text-primary flex items-center cursor-pointer" (click)="filterButtonClicked.emit()">
  <svg-icon key="filters" class="!text-sm"></svg-icon>
  <span class="pl-sm text-base leading-base">Filters</span>
  <div
    class="ml-sm text-white bg-primary text-2xs leading-2xs font-semibold rounded-full w-[18px] h-[18px] flex items-center justify-center"
    *ngIf="numberOfFilters && numberOfFilters > 0"
  >
    {{ numberOfFilters }}
  </div>
</div>
