<div class="modal-container relative pb-2xl">
  <!-- Close Btn -->
  <div class="absolute z-50 cursor-pointer top-base right-lg text-txt">
    <svg-icon key="close" class="!text-2xs" (click)="dialogRef.close()"></svg-icon>
  </div>

  <lla-invoice-info
    [forModal]="true"
    [showCloseButton]="false"
    [invoiceId]="data.invoiceId"
  ></lla-invoice-info>
</div>
