import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ISelectItem } from '@lla-platform/core/core-ui'
import { UntilDestroy } from '@ngneat/until-destroy'
import { PillSize } from '../pill/pill.component'

@UntilDestroy()
@Component({
  selector: 'lla-items-selector-filter',
  templateUrl: './items-selector-filter.component.html',
  styleUrls: ['./items-selector-filter.component.scss']
})
export class ItemsSelectorFilterComponent {
  @Input() icon = 'garage'
  @Input() label = 'Select location'
  @Input() allItems: ISelectItem[] = []
  @Input() selectedId: string
  @Input() pillSize: PillSize = 'm'
  @Input() extraClasses = ''
  @Output() selectedIdChanged = new EventEmitter<string>()

  selectedItemName() {
    return this.allItems.find((el) => el.value === this.selectedId)?.label
  }
}
