import { INew_TableAction } from '@lla-platform/core/core-data-access'
import { IGpPackageListRequest } from '../../interfaces/invoice-gp-package.interface'
import { IPackageUnperformedRequest } from '../../interfaces/package-unperformed.interface'
import { IArInvoiceRequest } from '../../interfaces/ar-invoices.interface'
import { IInvoiceGrossProfitRequest } from '../../interfaces/invoice-gross-profit.interface'

export class GetArInvoicesSummary {
  static readonly type = '[arInvoices] Get Ar Invoices Summary'
  constructor(public payload: IArInvoiceRequest) {}
}

export class GetArInvoicesList {
  static readonly type = '[arInvoices] Get Ar Invoices List'
  constructor(public info: INew_TableAction) {}
}

export class GetGrossProfitList {
  static readonly type = '[arInvoices] Get Gross Profit List'
  constructor(public info: INew_TableAction) {}
}

export class GetInvoice {
  static readonly type = '[arInvoices] Get Invoice'
  constructor(public invoiceId: string) {}
}

export class GetGrossProfit {
  static readonly type = '[arInvoices] Get Gross Profit'
  constructor(public payload: IInvoiceGrossProfitRequest) {}
}

export class GetGpPackageList {
  static readonly type = '[arInvoices] Get Gp Package List'
  constructor(public info: INew_TableAction) {}
}

export class GetGpPackage {
  static readonly type = '[arInvoices] Get Gp Package'
  constructor(public payload: IGpPackageListRequest) {}
}

export class GetPackageUnperformed {
  static readonly type = '[arInvoices] Get Package Unperformed'
  constructor(public payload: IPackageUnperformedRequest) {}
}
