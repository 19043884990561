import { Injectable } from '@angular/core'
import {
  HttpService,
  IExportFileResponse,
  ITimePeriodRequest,
  TableActionsService,
  TableFilterService
} from '@lla-platform/core/core-data-access'
import { DateService } from '@lla-platform/core/core-util'
import { Observable } from 'rxjs'
import {
  ICommunicationHistoryResponse,
  ICommunicationRequest,
  ICommunicationResponse,
  IUpdateCommunicationRequest
} from '../interfaces/communications.interface'
import { ICustomerInvoicesResponse } from '../interfaces/customers-invoice.interface'
import { ICustomersListInfo, ICustomersListResponse } from '../interfaces/customers-list.interface'
import { ITagAddRequest, ITagDeleteRequest, ITagResponse } from '../interfaces/customers-tag.interface'
import {
  ICustomerGetResponse,
  ICustomerRequest,
  ICustomersTypeResponse,
  ICustomerUpdateRequest
} from '../interfaces/customers.interface'
import { CUSTOMERS_API_URL } from './customers-api-routes'
import {
  ICommunicationsListInfo,
  ICommunicationsListRequest
} from '../interfaces/communications-list.interface'
import { ICommunicationsListResponse } from '../interfaces/communications-list.interface'
import { IAgent } from '../interfaces/agents.interface'
import {
  ICommunicationTypeRequest,
  ICommunicationsTypeResponse
} from '../interfaces/communication-type.interface'
import {
  ICustomerGeoLocationRequest,
  ICustomerGeoLocationResponse
} from '../interfaces/customers-geolocation.interface'
import { IDeferredTypeRequest, IDeferredTypeResponse } from '../interfaces/deferred.interface'

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  constructor(
    private http: HttpService,
    private dateService: DateService,
    private tableFilterService: TableFilterService,
    private tableActionsService: TableActionsService
  ) {}

  // Customers
  getCustomersTypes(payload: ICustomerRequest): Observable<ICustomersTypeResponse> {
    const { from, to } = payload
    return this.http.post(CUSTOMERS_API_URL.customersTypes, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportCustomersTypes(payload: ITimePeriodRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(CUSTOMERS_API_URL.exportCustomersTypes, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getCustomersList(payload: ICustomersListInfo): Observable<ICustomersListResponse> {
    const tableInfo = this.tableActionsService.createRequestPayload(payload.tableAction)

    return this.http.post(CUSTOMERS_API_URL.customersList, {
      ...tableInfo,
      ...(payload.filters ?? {}),
      period: this.getPeriodObject(payload.filters?.period),
      invoiceMin: payload.filters?.invoiceMin === 0 ? null : payload.filters?.invoiceMin,
      tableAction: undefined,
      filters: undefined,
      from: undefined,
      to: undefined
    })
  }

  exportCustomersList(payload: ICustomersListInfo): Observable<IExportFileResponse> {
    const tableInfo = this.tableActionsService.createRequestPayload(payload.tableAction)

    return this.http.post(CUSTOMERS_API_URL.exportCustomersList, {
      ...tableInfo,
      ...(payload.filters ?? {}),
      period: this.getPeriodObject(payload.filters?.period),
      invoiceMin: payload.filters?.invoiceMin === 0 ? null : payload.filters?.invoiceMin,
      tableAction: undefined,
      filters: undefined,
      from: undefined,
      to: undefined
    })
  }

  getCustomer(customerId: string, shopId: string): Observable<ICustomerGetResponse> {
    return this.http.get(CUSTOMERS_API_URL.getcustomer(customerId, shopId))
  }

  updateCustomer(customerId: string, payload: ICustomerUpdateRequest) {
    return this.http.patch(CUSTOMERS_API_URL.updateCustomer(customerId), payload)
  }

  getCustomersInRadius(payload: ICustomerGeoLocationRequest): Observable<ICustomerGeoLocationResponse> {
    return this.http.post(CUSTOMERS_API_URL.geoLocation, payload)
  }

  exportCustomersInRadius(payload: ICustomerGeoLocationRequest): Observable<IExportFileResponse> {
    return this.http.post(CUSTOMERS_API_URL.exportGeoLocation, payload)
  }

  // Customers Tags
  getCustomerTags(customerId: string, shopId: string): Observable<ITagResponse> {
    return this.http.get(CUSTOMERS_API_URL.customerGetTags(customerId, shopId))
  }

  addCustomerTag(customerId: string, payload: ITagAddRequest) {
    return this.http.post(CUSTOMERS_API_URL.customerTagBase(customerId), payload)
  }

  deleteCustomerTag(customerId: string, payload: ITagDeleteRequest) {
    return this.http.delete(CUSTOMERS_API_URL.customerTagBase(customerId), payload)
  }

  // Customer Invoices
  getCustomerInvoices(customerId: string, shopId: string): Observable<ICustomerInvoicesResponse> {
    return this.http.get(CUSTOMERS_API_URL.getCustomerInvoices(customerId, shopId))
  }

  // Communication
  getCommunication(customerId: string, shopId: string, id: string): Observable<ICommunicationResponse> {
    return this.http.get(CUSTOMERS_API_URL.getCommunication(customerId, shopId, id))
  }

  getCommunicationHistory(
    customerId: string,
    shopId: string
  ): Observable<ICommunicationHistoryResponse> {
    return this.http.get(CUSTOMERS_API_URL.getCommunicationHistory(customerId, shopId))
  }

  addCommunication(customerId: string, payload: ICommunicationRequest) {
    return this.http.post(CUSTOMERS_API_URL.addCommunication(customerId), payload)
  }

  updateCommunication(customerId: string, payload: IUpdateCommunicationRequest) {
    return this.http.put(CUSTOMERS_API_URL.updateCommunication(customerId), payload)
  }

  getCommunicationsList(payload: ICommunicationsListRequest): Observable<ICommunicationsListResponse> {
    const { period } = payload
    return this.http.post(CUSTOMERS_API_URL.communicationsLists, {
      ...payload,
      period: this.getPeriodObject(period)
    } as ICommunicationsListRequest)
  }

  exportCommunicationsList(payload: ICommunicationsListInfo): Observable<IExportFileResponse> {
    const { period } = payload.filters as ICommunicationsListRequest
    return this.http.post(CUSTOMERS_API_URL.exportCommunicationsList, {
      ...payload.filters,
      period: this.getPeriodObject(period),
      ...this.tableFilterService.createBaseRequestPayload(payload.tableInfo)
    })
  }

  getAgents(): Observable<IAgent[]> {
    return this.http.get(CUSTOMERS_API_URL.getAgents)
  }

  // Communication type
  getCommunicationType(payload: ICommunicationTypeRequest): Observable<ICommunicationsTypeResponse> {
    return this.http.post(CUSTOMERS_API_URL.getCommunicationType, payload)
  }

  exportCommunicationType(payload: ICommunicationTypeRequest): Observable<IExportFileResponse> {
    return this.http.post(CUSTOMERS_API_URL.exportCommunicationType, payload)
  }

  // Deferred Types
  getDeferredTypes(payload: IDeferredTypeRequest): Observable<IDeferredTypeResponse> {
    const { from, to } = payload
    return this.http.post(CUSTOMERS_API_URL.deferredTypes, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportDeferredTypes(payload: IDeferredTypeRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(CUSTOMERS_API_URL.exportDeferredTypes, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  private getPeriodObject(period?: ITimePeriodRequest) {
    return period?.periodType
      ? {
          periodType: period?.periodType,
          from: this.dateService.momentToDateString(period?.from),
          to: this.dateService.momentToDateString(period?.to)
        }
      : null
  }
}
