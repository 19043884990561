<div class="flex items-center {{ extraClass }}">
  <input
    type="checkbox"
    class="form-checkbox mr-sm rounded-xs text-primary focus:outline-none focus:ring-transparent focus:shadow-none"
    style="box-shadow: none !important"
    [ngStyle]="{ width: size + 'px', height: size + 'px' }"
    (click)="toggleCheckbox(item?.value)"
    [checked]="item ? formControl.value?.includes(item.value) : formControl.value"
    [disabled]="disabled"
  />
  <div
    class="text-sm leading-sm text-txt cursor-pointer {{ extraLabelClass }}"
    [ngClass]="{ '!text-txt-disabled': disabled }"
    [ngStyle]="{ fontSize: size + 'px' }"
    (click)="toggleCheckbox(item?.value)"
  >
    {{ item?.label ?? label }}
  </div>
</div>
