<div class="relative">
  <div
    class="text-center border rounded-full {{ extraClass }}"
    [ngClass]="{
      'm-size': size === 'm',
      's-size': size === 's',
      'bg-primary border-primary text-white': selected,
      'border-gray-100 text-txt': !selected,
      'hover:bg-ui-hover': !selected && hasHoverEffect,
      'cursor-pointer': hasHoverEffect
    }"
  >
    {{ label }}
  </div>
</div>
