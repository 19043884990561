<div class="flex items-center justify-end" *ngIf="rowId !== totalRowUniqueId">
  <div>
    {{ invoiceNumber }}
  </div>
  <div
    class="flex ml-lg text-gray-400 hover:text-primary"
    matTooltip="Copy"
    matTooltipClass="invoice-info-cell-tooltip"
    [matTooltipPosition]="'right'"
  >
    <svg-icon
      key="copy"
      ngxClipboard
      [cbContent]="invoiceNumber"
      (cbOnSuccess)="toastrService.success('Copied to clipboard successfully')"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      class="!text-sm"
    ></svg-icon>
  </div>
</div>
