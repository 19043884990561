import { ISelectItem } from '@lla-platform/core/core-ui'

export enum WorkOrderStage {
  EstimateCompleted = 'EstimateCompleted',
  WorkCompleted = 'WorkCompleted',
  ScheduledWork = 'ScheduledWork',
  Unassigned = 'Unassigned',
  OnHold = 'OnHold',
  InspectionInProgress = 'InspectionInProgress',
  WorkAuthorized = 'WorkAuthorized',
  InspectionCompleted = 'InspectionCompleted'
}

export const WorkOrderStageToLabel: { [key: string]: string } = {
  EstimateCompleted: 'Estimate Completed',
  WorkCompleted: 'Work Completed',
  ScheduledWork: 'Scheduled Work',
  Unassigned: 'Unassigned',
  OnHold: 'On Hold',
  InspectionInProgress: 'Inspection in Progress',
  WorkAuthorized: 'Work Authorized',
  InspectionCompleted: 'Inspection Completed'
}

export const WorkOrderStageItems: ISelectItem[] = [
  {
    label: WorkOrderStageToLabel[WorkOrderStage.EstimateCompleted],
    value: WorkOrderStage.EstimateCompleted
  },
  {
    label: WorkOrderStageToLabel[WorkOrderStage.WorkCompleted],
    value: WorkOrderStage.WorkCompleted
  },
  {
    label: WorkOrderStageToLabel[WorkOrderStage.ScheduledWork],
    value: WorkOrderStage.ScheduledWork
  },
  {
    label: WorkOrderStageToLabel[WorkOrderStage.Unassigned],
    value: WorkOrderStage.Unassigned
  },
  {
    label: WorkOrderStageToLabel[WorkOrderStage.OnHold],
    value: WorkOrderStage.OnHold
  },
  {
    label: WorkOrderStageToLabel[WorkOrderStage.InspectionInProgress],
    value: WorkOrderStage.InspectionInProgress
  },
  {
    label: WorkOrderStageToLabel[WorkOrderStage.WorkAuthorized],
    value: WorkOrderStage.WorkAuthorized
  },
  {
    label: WorkOrderStageToLabel[WorkOrderStage.InspectionCompleted],
    value: WorkOrderStage.InspectionCompleted
  }
]
