import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Sort } from '@angular/material/sort'
import { UntilDestroy } from '@ngneat/until-destroy'
import { SortStateService } from '../../services/sort-state.services'

export interface ITableHeaderSortInfo {
  columnName: string
  title?: string
}

@UntilDestroy()
@Component({
  selector: 'lla-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent {
  @Input() title: string
  @Input() sortInfo: ITableHeaderSortInfo[] = []
  @Input() extraClass = ''
  @Input() rightAlign = false
  @Input() centerAlign = false
  @Input() sortable = true
  @Input() sortState: Sort
  @Output() sortChanged = new EventEmitter()

  constructor(public sortStateService: SortStateService) {}

  isHeaderActive() {
    return (
      this.sortState &&
      this.sortState.direction &&
      (this.sortState.direction as string) !== '' &&
      this.sortInfo.find((el) => el.columnName === this.sortState.active)
    )
  }
}
