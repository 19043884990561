import { ISelectItem } from '@lla-platform/core/core-ui'

export enum MissedReasonType {
  NotInStock = 'NotInStock',
  PriceTooHigh = 'PriceTooHigh',
  PriceTooLow = 'PriceTooLow',
  InventoryRequest = 'InventoryRequest',
  Other = 'Other'
}

export const MissedReasonTypeToLabel: { [key: string]: string } = {
  NotInStock: 'Not In Stock',
  PriceTooHigh: 'Price Too High',
  PriceTooLow: 'Price Too Low',
  InventoryRequest: 'Inventory Request',
  Other: 'Other'
}

export const MissedReasonTypeItems: ISelectItem[] = [
  {
    label: MissedReasonTypeToLabel[MissedReasonType.NotInStock],
    value: MissedReasonType.NotInStock
  },
  {
    label: MissedReasonTypeToLabel[MissedReasonType.PriceTooHigh],
    value: MissedReasonType.PriceTooHigh
  },
  {
    label: MissedReasonTypeToLabel[MissedReasonType.PriceTooLow],
    value: MissedReasonType.PriceTooLow
  },
  {
    label: MissedReasonTypeToLabel[MissedReasonType.InventoryRequest],
    value: MissedReasonType.InventoryRequest
  },
  {
    label: MissedReasonTypeToLabel[MissedReasonType.Other],
    value: MissedReasonType.Other
  }
]
