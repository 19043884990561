import { ISelectItem } from '@lla-platform/core/core-ui'

export enum TireType {
  NotSet = 'NotSet',
  Bfs = 'Bfs',
  Advanta = 'Advanta'
}

export const TireTypeToLabel: { [key: string]: string } = {
  NotSet: '',
  Bfs: 'Bfs',
  Advanta: 'Advanta'
}

export const TireTypeItems: ISelectItem[] = [
  {
    label: TireTypeToLabel[TireType.Advanta],
    value: TireType.Advanta
  },
  {
    label: TireTypeToLabel[TireType.Bfs],
    value: TireType.Bfs
  }
]
