import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ShopsStateModel } from './shops.model'
import { ShopsService } from '../shops.service'
import { GetAllShops } from './shops.actions'
import { sortBy, uniq } from 'lodash'
import { ISelectItem } from '@lla-platform/core/core-ui'

@State<ShopsStateModel>({
  name: 'shops'
})
@Injectable()
export class ShopsState {
  constructor(private shopsService: ShopsService) {}

  @Selector()
  static allShops(state: ShopsStateModel) {
    return sortBy(state.allShops, (x) => x.name)
  }

  @Selector()
  static userShopsAsList(state: ShopsStateModel): ISelectItem[] {
    return sortBy(
      (state.allShops ?? []).filter((el) => el.userHasAccess),
      (x) => x.name
    ).map((el) => ({ value: el.id, label: el.name ?? '', extraInfo: el }))
  }

  @Selector()
  static states(state: ShopsStateModel) {
    return uniq((state.allShops ?? []).map((el) => el.state ?? []).flat())
  }

  @Action(GetAllShops)
  getCallsReport(ctx: StateContext<ShopsStateModel>) {
    return this.shopsService.getAllShops().pipe(
      tap((res) => {
        ctx.patchState({
          allShops: res
        })
      })
    )
  }
}
