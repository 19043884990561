import { Component, Input, OnInit } from '@angular/core'
import { ControlContainer, UntypedFormControl } from '@angular/forms'
import { ISelectItem } from '@lla-platform/core/core-ui'
import { ShopsState } from '@lla-platform/shops/shops-data-access'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'

export interface ITableHeaderSortInfo {
  columnName: string
  title?: string
}

@UntilDestroy()
@Component({
  selector: 'lla-shops-selector',
  templateUrl: './shops-selector.component.html',
  styleUrls: ['./shops-selector.component.scss']
})
export class ShopsSelectorComponent implements OnInit {
  @Input() controlName: string
  @Input() selectedGroup?: string
  @Input() titleExtraClass = ''

  formControl: UntypedFormControl
  statesList: string[] = []
  shopsList: ISelectItem[] = []

  constructor(
    private store: Store,
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    this.formControl = this.controlContainer.control?.get(this.controlName) as UntypedFormControl
    this.shopsList = this.store.selectSnapshot<ISelectItem[]>(ShopsState.userShopsAsList)
    this.statesList = this.store.selectSnapshot<string[]>(ShopsState.states)
  }

  selectedGroupChanged(item: string) {
    this.selectedGroup = item
    const selectedShopsId = this.shopsList
      .filter((el) => el?.extraInfo?.state === item)
      .map((el) => el.value)
    this.formControl?.setValue(selectedShopsId)
  }

  allShopsSelected() {
    const locationIds = this.formControl?.value
    return locationIds && locationIds.length === this.shopsList.length
  }

  selectAllShops() {
    this.selectedGroup = undefined
    const locationIds = this.formControl?.value
    if (locationIds && locationIds.length === this.shopsList.length) {
      this.formControl?.reset()
      return
    }
    this.formControl?.setValue(this.shopsList.map((el) => el.value))
  }
}
