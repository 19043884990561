import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { MainLayoutComponent } from './main-layout/main-layout.component'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { CoreFeatureModule } from '@lla-platform/core/core-feature'
import { MatProgressBarModule } from '@angular/material/progress-bar'

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    SvgIconsModule,
    MatMenuModule,
    MatIconModule,
    CoreFeatureModule,
    MatProgressBarModule
  ],
  exports: [MainLayoutComponent]
})
export class MainLayoutModule {}
