import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IAvailableWeeksResponse } from '../interfaces/available-weeks.interface'
import { TARGETS_API_URL } from './targets-api-routes'
import { IWeekTargetResponse } from '../interfaces/weekly-target.interface'

@Injectable({
  providedIn: 'root'
})
export class TargetsService {
  constructor(private http: HttpService) {}

  getAvailableWeeks(): Observable<IAvailableWeeksResponse> {
    return this.http.get(TARGETS_API_URL.availableWeeks)
  }

  getShopsTargets(weekNumber: number): Observable<IWeekTargetResponse> {
    return this.http.post(TARGETS_API_URL.shopsTargets, { weekNumber })
  }

  getShopsTargetsComps(weekNumber: number): Observable<IWeekTargetResponse> {
    return this.http.post(TARGETS_API_URL.shopsTargetsComps, { weekNumber })
  }
}
