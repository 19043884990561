<div class="w-full flex h-[100dvh] overflow-hidden">
  <div class="flex-1 p-[64px] bg-primary h-full hidden lg:flex items-center justify-center">
    <img class="h-[64px]" src="assets/images/icons/new-big-logo-white2.svg" />
  </div>
  <div class="flex-1 h-full overflow-auto">
    <div class="flex flex-col w-full lg:max-w-[376px] mx-auto h-full">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
