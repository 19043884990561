import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { CommonService } from '@lla-platform/core/core-util'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup

  selectedGroup?: string

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: { locationIds?: string[] }
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      locationIds: [null]
    })

    this.form?.patchValue({
      locationIds: this.data?.locationIds ?? []
    })
  }

  resetForm() {
    if (!this.form) {
      return
    }
    this.selectedGroup = undefined
    this.form.patchValue(
      {
        locationIds: null
      },
      { emitEvent: false }
    )
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      this.dialogRef.close(this.commonService.convertEmptyToNull(this.form.value))
    }
  }
}
