import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  CalendarMaxDate,
  IRangeDateInfo,
  PeriodType,
  PeriodTypeToLabel
} from '@lla-platform/core/core-data-access'
import { DateService } from '@lla-platform/core/core-util'
import { UntilDestroy } from '@ngneat/until-destroy'
import { cloneDeep } from 'lodash'

@UntilDestroy()
@Component({
  selector: 'lla-range-date-picker-filter',
  templateUrl: './range-date-picker-filter.component.html',
  styleUrls: ['./range-date-picker-filter.component.scss']
})
export class RangeDatePickerFilterComponent implements OnChanges {
  @Input() value: IRangeDateInfo
  @Input() maxDate: CalendarMaxDate = 'Today'
  @Input() minDate?: string
  @Input() hiddenPeriods: PeriodType[] = []
  @Input() hideAllPeriods = false
  @Input() extraClasses = ''
  @Output() valueChanged = new EventEmitter<IRangeDateInfo>()

  formattedPeriodTime?: string = ''
  selectedValue: IRangeDateInfo
  selectedPeriodType?: PeriodType
  periodTypeToLabel = PeriodTypeToLabel
  menuIsOpen = false

  constructor(private dialogService: MatDialog, private dateService: DateService) {}

  ngOnChanges(): void {
    this.setSelectedDate()
  }

  setSelectedDate() {
    this.selectedValue = cloneDeep(this.value)
    this.selectedPeriodType = this.selectedValue?.periodType
    setTimeout(() => {
      this.formattedPeriodTime = this.dateService.momentCalculatedFormattedPeriod(this.value?.rangeDate)
    })
  }
}
