<div (click)="menuTrigger.openMenu()">
  <div #contentSlot>
    <ng-content></ng-content>
  </div>

  <div
    *ngIf="!contentSlot.children.length"
    class="w-full md:w-max md:min-w-[244px] p-xs pl-sm rounded-sm bg-white md:hover:bg-ui-hover flex justify-between items-center cursor-pointer {{
      extraClasses
    }}"
  >
    <div class="flex-1 overflow-hidden pr-sm flex flex-col">
      <div class="text-2xs leading-2xs text-txt-secondary capitalize">{{ label }}</div>
      <div class="text-sm leading-sm truncate" *ngIf="selectedId">
        {{ selectedItemName() }}
      </div>
    </div>

    <div class="flex items-center justify-center p-base bg-primary rounded-sm text-contrast">
      <svg-icon [key]="icon" class="!text-base"></svg-icon>
    </div>
  </div>
</div>

<div [matMenuTriggerFor]="locationSelectorMenu" #menuTrigger="matMenuTrigger"></div>

<mat-menu
  #locationSelectorMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="lla-filter-menu-full"
>
  <div
    class="flex flex-col overflow-auto w-full md:w-max md:min-w-[300px] p-base beautyScroll"
    (click)="$event.stopPropagation()"
  >
    <lla-items-selector
      [label]="label"
      [allItems]="allItems"
      [selectedId]="selectedId"
      [pillSize]="pillSize"
      (selectedIdChanged)="selectedIdChanged.emit($event); menuTrigger.closeMenu()"
      (canceld)="menuTrigger.closeMenu()"
    ></lla-items-selector>
  </div>
</mat-menu>
