import {
  ContactType,
  IAdvancedTable,
  IPaginationFilteredResponse
} from '@lla-platform/core/core-data-access'
import { PaymentMethod } from '../enums/payment-method.enum'

export interface IArInvoicesListItem extends IAdvancedTable {
  contactId?: string
  locationId?: string
  locationName: string
  invoiceId?: string
  invoiceNumber?: number
  invoiceDate?: Date
  companyName?: string
  firstName?: string
  lastName?: string
  totalWithTax: number
  formattedTotalWithTax: string
  totalWithoutTax: number
  formattedTotalWithoutTax: string
  paymentMethod?: PaymentMethod
  contactType?: ContactType
  contactLink?: string
}

export interface IArInvoicesListTotal {
  totalWithTax: number
  formattedTotalWithTax: string
  totalWithoutTax: number
  formattedTotalWithoutTax: string
}

export interface IArInvoicesListResponse extends IPaginationFilteredResponse {
  invoices: IArInvoicesListItem[]
  totals: IArInvoicesListTotal
}
