<div class="h-full flex flex-col">
  <div class="py-lg px-xl border-b border-gray-100">
    <img
      class="h-2xl cursor-pointer"
      src="assets/images/icons/new-big-logo.svg"
      alt="Logo"
      [routerLink]="'/'"
    />
  </div>

  <div class="flex-1 flex flex-col overflow-auto beautyScroll">
    <router-outlet></router-outlet>
  </div>
</div>
