<button
  [attr.type]="type"
  [disabled]="disabled || loading"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-secondary': variant === 'secondary',
    'btn-error': variant === 'error',
    'btn-black': variant === 'black',
    'btn-black-invert': variant === 'black-invert',
    'btn-disabled': this.disabled,
    'btn-ghost': variant === 'ghost',
    'size-medium': size === 'medium',
    'size-small': size === 'small',
    'size-small-shrink': size === 'small-shrink',
    'size-medium-shrink': size === 'medium-shrink',
  }"
  class="btn-base {{ extraBtnClass }}"
  [disabled]="loading"
>
  <div
    class="w-full flex justify-center items-center"
    [ngClass]="{ 'flex-row-reverse': iconPosition === 'right' }"
  >
    <div
      *ngIf="loading"
      class="animate-spin rounded-full h-base w-base border-b-2 border-white {{ loadingExtraClass }}"
    ></div>
    <div *ngIf="!loading && icon">
      <div [ngSwitch]="iconType" class="flex items-center justify-center">
        <img *ngSwitchCase="'iconPath'" class="{{ iconClass }}" [src]="icon" />
        <svg-icon *ngSwitchCase="'svgIcon'" [key]="icon" class="{{ iconClass }}"></svg-icon>
        <mat-icon *ngSwitchDefault class="{{ iconClass }}">{{ icon }}</mat-icon>
      </div>
    </div>
    <div
      class="lowercase first-letter:uppercase"
      [ngClass]="{
        'mr-sm': (icon || loading) && iconPosition === 'right',
        'ml-sm': (icon || loading) && iconPosition === 'left'
      }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</button>
