export class GetAvailableWeeksForWeeklyTarget {
  static readonly type = '[WeeklyTarget] Get Available Weeks For Weekly Target'
}

export class GetShopsTargets {
  static readonly type = '[WeeklyTarget] Get Shops Targets'
  constructor(public weekNumber: number) {}
}

export class GetShopsTargetsComps {
  static readonly type = '[WeeklyTarget] Get Shops Targets Comps'
  constructor(public weekNumber: number) {}
}
