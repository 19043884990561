import { Injectable } from '@angular/core'
import {
  HttpService,
  IExportFileResponse,
  INew_TableAction,
  TableActionsService
} from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IPaymentsListResponse } from '../interfaces/payments-list.interface'
import { PAYMENTS_API_URL } from './payments-api-routes'

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  constructor(private http: HttpService, private tableActionsService: TableActionsService) {}

  getPaymentsList(info: INew_TableAction): Observable<IPaymentsListResponse> {
    return this.http.post(
      PAYMENTS_API_URL.paymentsList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  exportPaymentsList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      PAYMENTS_API_URL.exportPaymentsList,
      this.tableActionsService.createRequestPayload(info)
    )
  }
}
