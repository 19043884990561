import { Injectable } from '@angular/core'
import {
  IStringTimePeriod,
  ITimePeriodRequest,
  PeriodType,
  PeriodTypeButtonsInfo
} from '@lla-platform/core/core-data-access'
import { IRangeDateValue } from '@lla-platform/core/core-ui'
import moment, { Moment } from 'moment'

@Injectable({
  providedIn: 'root'
})
export class DateService {
  momentToDateString(date: string | Date | Moment | undefined) {
    if (!date) {
      return
    }
    return moment(date).utc().format().split('T')[0]
  }

  momentCalculatedFormattedPeriod(rangeDate: IRangeDateValue | undefined, withoutYear = false) {
    let formattedPeriodTime = ''
    if (!rangeDate || !rangeDate?.from || !rangeDate?.to) {
      return
    }

    const { from, to } = {
      from: moment(rangeDate.from).utc(),
      to: moment(rangeDate.to).utc()
    }
    formattedPeriodTime +=
      from.isSame(to, 'year') || withoutYear ? from.format('MMM D') : from.format('MMM D, YYYY')
    formattedPeriodTime += ` - ${withoutYear ? to.format('MMM D') : to.format('MMM D, YYYY')}`

    return formattedPeriodTime
  }

  getFullDateWithoutTimeZone(info: { date: Date; hour?: number; min?: number }) {
    const date = new Date(info.date)
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}T${
      info.hour ? (info.hour > 9 ? info.hour : `0${info.hour}`) : '00'
    }:${info.min ? (info.min > 9 ? info.min : `0${info.min}`) : '00'}:00.000Z`
  }

  periodTypeToDate(periodType: PeriodType): Required<IRangeDateValue> {
    let dateInfo = PeriodTypeButtonsInfo().find((el) => el.value === periodType)?.extraInfo
    if (!dateInfo) {
      dateInfo = PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.WeekToDate)?.extraInfo
    }

    return { from: dateInfo.from.format(), to: dateInfo.to.format() }
  }

  generateTimePeriod(value: Partial<ITimePeriodRequest>, shouldConvert = true): IStringTimePeriod {
    if (shouldConvert) {
      return {
        from: value?.from ? this.momentToDateString(value.from) : undefined,
        to: value?.to ? this.momentToDateString(value.to) : undefined
      }
    } else {
      return {
        from: value?.from as string,
        to: value?.to as string
      }
    }
  }

  periodTypeToDateString(periodType: PeriodType) {
    const dateInfo = this.periodTypeToDate(periodType)
    return this.generateTimePeriod(dateInfo)
  }
}
