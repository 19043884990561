import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { WeeklyTargetStateModel } from './weekly-target.model'
import { TargetsService } from '../targets.service'
import {
  GetAvailableWeeksForWeeklyTarget,
  GetShopsTargets,
  GetShopsTargetsComps
} from './weekly-target.actions'
import { sortBy, uniqBy } from 'lodash'
import { ShopsState } from '@lla-platform/shops/shops-data-access'
import { IWeekTargetResponse } from '../../interfaces/weekly-target.interface'

@State<WeeklyTargetStateModel>({
  name: 'weeklyTarget'
})
@Injectable()
export class WeeklyTargetState {
  constructor(
    private TargetsService: TargetsService,
    private store: Store
  ) {}

  @Selector()
  static availableWeeks(state: WeeklyTargetStateModel) {
    return state.availableWeeks ?? []
  }

  @Selector()
  static weeklyTargets(state: WeeklyTargetStateModel): IWeekTargetResponse | undefined {
    return state.weeklyTargets
      ? {
          currentWeek: state.weeklyTargets?.currentWeek,
          lastYearWeek: state.weeklyTargets?.lastYearWeek,
          locations: state.weeklyTargets?.locations?.map((el) => ({
            ...el,
            items: el.items?.map((item) => ({
              ...item,
              locationName: el.location?.name,
              locationType: el.location?.locationType
            }))
          }))
        }
      : undefined
  }

  @Selector()
  static allRegions(state: WeeklyTargetStateModel) {
    const data = state.weeklyTargets?.locations ?? []
    return [
      { label: 'Organization', value: 'all' },
      ...sortBy(
        uniqBy(
          data
            .map((el) => ({
              label: el.locationInfo?.region ?? '',
              value: el.locationInfo?.region ?? ''
            }))
            .filter((el) => el.value !== ''),
          (x) => x.value
        ),
        (x) => x.label
      )
    ]
  }

  @Selector()
  static allDivisions(state: WeeklyTargetStateModel) {
    const data = state.weeklyTargets?.locations ?? []
    return [
      { label: 'All', value: 'all' },
      ...sortBy(
        uniqBy(
          data
            .map((el) => ({
              label: el.locationInfo?.division ?? '',
              value: el.locationInfo?.division ?? ''
            }))
            .filter((el) => el.value !== ''),
          (x) => x.value
        ),
        (x) => x.label
      )
    ]
  }

  @Action(GetAvailableWeeksForWeeklyTarget)
  getAvailableWeeksForWeeklyTarget(ctx: StateContext<WeeklyTargetStateModel>) {
    const availableWeeks = ctx.getState().availableWeeks
    if (ctx.getState().availableWeeks) {
      return availableWeeks
    }

    return this.TargetsService.getAvailableWeeks().pipe(
      tap((res) => {
        ctx.patchState({
          availableWeeks: res?.availableWeeks ?? []
        })
      })
    )
  }

  @Action(GetShopsTargets)
  getShopsTargets(ctx: StateContext<WeeklyTargetStateModel>, { weekNumber }: GetShopsTargets) {
    return this.TargetsService.getShopsTargets(weekNumber).pipe(
      tap((res) => {
        const allShops = this.store.selectSnapshot(ShopsState.allShops)
        ctx.patchState({
          weeklyTargets: {
            currentWeek: res?.currentWeek,
            lastYearWeek: res?.lastYearWeek,
            locations: (res?.locations ?? []).map((el) => ({
              ...el,
              locationInfo: allShops.find((s) => s.id === el.location?.id)
            }))
          }
        })
      })
    )
  }

  @Action(GetShopsTargetsComps)
  getShopsTargetsComps(ctx: StateContext<WeeklyTargetStateModel>, { weekNumber }: GetShopsTargetsComps) {
    return this.TargetsService.getShopsTargetsComps(weekNumber).pipe(
      tap((res) => {
        const allShops = this.store.selectSnapshot(ShopsState.allShops)
        ctx.patchState({
          weeklyTargets: {
            currentWeek: res?.currentWeek,
            lastYearWeek: res?.lastYearWeek,
            locations: (res?.locations ?? []).map((el) => ({
              ...el,
              locationInfo: allShops.find((s) => s.id === el.location?.id)
            }))
          }
        })
      })
    )
  }
}
