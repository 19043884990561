import { Injectable } from '@angular/core'
import { HttpService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IStockableItems } from '../interfaces/stockable.interface'
import { STOCKABLE_API_URL } from './stockable-api-routes'

@Injectable({
  providedIn: 'root'
})
export class StockableService {
  constructor(private http: HttpService) {}

  getAllStockables(locationId: string): Observable<IStockableItems> {
    return this.http.get(STOCKABLE_API_URL.stockableBase(locationId))
  }

  saveAllStockables(locationId: string, payload: IStockableItems) {
    return this.http.post(STOCKABLE_API_URL.stockableBase(locationId), payload)
  }

  replaceStockables() {
    return this.http.post(STOCKABLE_API_URL.replaceStockables, {})
  }

  deleteStockable(locationId: string, stockableId: string) {
    return this.http.delete(STOCKABLE_API_URL.deleteStockable(locationId, stockableId))
  }
}
