export const ApplicationRoutes = {
  dashboard: 'dashboard',
  profile: 'profile',
  receivable: 'receivable',
  contacts: 'contacts',
  shopPerformance: 'location-invoices',
  samples: 'samples',
  targets: 'targets',
  google: 'google',
  reviews: 'reviews',
  reports: 'reports',
  payments: 'payments',
  admin: 'admin',
  promotions: 'promotions',
  tires: 'tires',
  tracker: 'tracker',
  performancePackageGp: 'performance-package-gp',
  survey: 'survey',
  whoToCall: 'who-to-call',
  workOrders: 'work-orders',
  stockable: 'stockable'
}
