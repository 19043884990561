import { Injectable } from '@angular/core'
import { DateService, environment } from '@lla-platform/core/core-util'

import { cloneDeep, isArray } from 'lodash'
import {
  IAdvancedTableBaseInfo,
  IAdvancedTableInfo,
  ITableFilter,
  ITableFilterItem,
  ITableFilterOption
} from '../interfaces/advanced-table.interface'
import {
  IPaginationBaseRequest,
  IPaginationFilteredRequest
} from '../interfaces/pagination-filtered.interface'
import { PeriodType } from '../enums/period-type.enum'

@Injectable({
  providedIn: 'root'
})
export class TableFilterService {
  constructor(private dateService: DateService) {}

  createBaseRequestPayload(info?: IAdvancedTableBaseInfo): IPaginationBaseRequest {
    const paylad: IPaginationBaseRequest = {
      top: environment.pageSize,
      skip: ((info?.page ?? 1) - 1) * environment.pageSize
    }

    if (info && info.sortState?.direction !== '') {
      const { active, direction } = info.sortState
      paylad['orderBy'] = [
        {
          direction: direction === 'asc' ? 'Ascending' : 'Descending',
          property: active
        }
      ]
    }

    return paylad
  }

  createRequestPayload(info: IAdvancedTableInfo): IPaginationFilteredRequest {
    const paylad: IPaginationFilteredRequest = {
      filters: {},
      top: environment.pageSize,
      skip: (info.page - 1) * environment.pageSize
    }

    if (info.sortState?.direction !== '') {
      const { active, direction } = info.sortState
      paylad['orderBy'] = [
        {
          direction: direction === 'asc' ? 'Ascending' : 'Descending',
          property: active
        }
      ]
    }

    const filters: ITableFilter = cloneDeep(info.filters ?? [])
    for (const key of Object.keys(filters)) {
      const filter = filters[key]
      if (!filter?.items || filter.items.length < 1) {
        delete filters[key]
        continue
      }
      let items: ITableFilterItem[] | undefined
      switch (filter.type) {
        case 'date':
          items = filter.items?.map((el) => ({
            operator: el.operator,
            value: this.dateService.momentToDateString(el.value)
          }))
          break

        case 'enum':
        case 'location-list':
        case 'category-list':
          if (filter.items && filter.items.length === 1 && isArray(filter.items[0].value)) {
            items = (filter.items[0].value ?? []).map((value: string) => ({
              operator: 'Equals',
              value
            }))
          } else {
            items = []
          }
          break

        case 'custom-date':
          items = filter.items?.map((el) => ({
            operator: el.operator,
            value: `${el.value}`,
            extraValue:
              el.value && el.value !== PeriodType.Custom
                ? this.dateService.periodTypeToDateString(el.value)
                : el.extraValue
          }))
          break

        default:
          items = filter.items?.map((el) => ({
            operator: el.operator,
            value: `${el.value}`
          }))
          break
      }
      filters[key] = {
        items: items ?? [],
        aggregator: filter.aggregator
      } as ITableFilterOption
    }
    paylad.filters = filters

    return paylad
  }
}
