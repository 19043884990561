<div class="md:hidden mb-base flex items-center">
  <div class="flex-1 text-base font-semibold leading-base">{{ label }}</div>
  <div class="flex text-txt-secondary cursor-pointer" (click)="canceld.emit()">
    <svg-icon key="close" class="!text-2xs"></svg-icon>
  </div>
</div>
<form [formGroup]="form">
  <lla-input
    class="block -mt-sm"
    [autoFocus]="true"
    [normalInputId]="searchInputId"
    *ngIf="allItems.length > 6"
    controlName="searchText"
    type="text"
    placeholder="Search"
    icon="search"
    size="small"
    [clearable]="true"
  ></lla-input>
</form>

<div>
  <div
    *ngFor="let item of selectedItems"
    class="py-sm px-base text-sm leading-sm text-txt hover:bg-ui-hover cursor-pointer"
    [ngClass]="{ '!bg-primary hover:!bg-primary !text-white': selectedId === item.value }"
    (click)="selectedIdChanged.emit(item.value)"
  >
    {{ item.label }}
  </div>
</div>
