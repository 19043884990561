import { IAdvancedTableInfo, INew_TableAction } from '@lla-platform/core/core-data-access'
import { ILostTireRequest } from '../../interfaces/lost-tires.interface'
import { ITiresRequest } from '../../interfaces/tires.interface'
import { IUpdateTireRequest } from '../../interfaces/tires-list.interface'

export class GetTiresSummary {
  static readonly type = '[tires] Get Tires Summary'
  constructor(public payload: ITiresRequest) {}
}

export class GetTiresByLevelSummary {
  static readonly type = '[tires] Get Tires By Level Summary'
  constructor(public payload: ITiresRequest) {}
}

export class GetTiresByStockableSummary {
  static readonly type = '[tires] Get Tires By Stockable Summary'
  constructor(public payload: ITiresRequest) {}
}

export class GetTiresList {
  static readonly type = '[tires] Get Tires List'
  constructor(public info: INew_TableAction) {}
}

export class UpdateTireItemIsTire {
  static readonly type = '[tires] Update Tire Item Is Tire'
  constructor(public itemId: string, public payload: IUpdateTireRequest) {}
}

export class GetLostTireInfo {
  static readonly type = '[tires] Get Lost Tire Info'
}

export class AddLostTire {
  static readonly type = '[tires] Add Lost Tire'
  constructor(public payload: ILostTireRequest) {}
}

export class GetLostTiresList {
  static readonly type = '[tires] Get Lost Tires List'
  constructor(public info: INew_TableAction) {}
}
