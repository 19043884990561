import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { StockableStateModel } from './stockable.model'
import { StockableService } from '../stockable.service'
import {
  DeleteStockable,
  GetAllStockable,
  ReplaceStockables,
  SaveAllStockable
} from './stockable.actions'

@State<StockableStateModel>({
  name: 'stockable'
})
@Injectable()
export class StockableState {
  constructor(private stockableService: StockableService) {}

  @Selector()
  static allStockables(state: StockableStateModel) {
    return state.allStockables ?? []
  }

  @Action(GetAllStockable)
  getAllStockable(ctx: StateContext<StockableStateModel>, { locationId }: GetAllStockable) {
    return this.stockableService.getAllStockables(locationId).pipe(
      tap((res) => {
        ctx.patchState({
          allStockables: res?.items ?? []
        })
      })
    )
  }

  @Action(SaveAllStockable)
  saveAllStockable(ctx: StateContext<StockableStateModel>, { locationId, payload }: SaveAllStockable) {
    return this.stockableService.saveAllStockables(locationId, payload)
  }

  @Action(ReplaceStockables)
  replaceStockables(ctx: StateContext<StockableStateModel>) {
    return this.stockableService.replaceStockables()
  }

  @Action(DeleteStockable)
  deleteStockable(ctx: StateContext<StockableStateModel>, { locationId, stockableId }: DeleteStockable) {
    return this.stockableService.deleteStockable(locationId, stockableId)
  }
}
