<div
  class="md:min-h-[54px] px-xl py-base border-t border-ui-separator flex flex-col md:flex-row"
  [ngClass]="{ 'bg-primary/15': isAdvancedMode }"
>
  <div class="flex-1 flex flex-wrap gap-sm">
    <ng-container *ngFor="let filter of filters">
      <div
        *ngIf="filter.text"
        class="flex items-center px-base py-xs rounded-sm border border-primary text-sm leading-sm text-primary"
      >
        <lla-table-filter
          [advancedFilterKey]="advancedFilterKey"
          [filter]="tableActions.filters?.[filter.key] ?? { key: filter.key }"
          [sort]="tableActions.sort"
          (filterChanged)="onFilterChanged(filter.key, $event)"
        >
          <span>
            {{ filter.text }}
          </span>
        </lla-table-filter>

        <div
          class="flex ml-base cursor-pointer text-txt-disabled hover:text-primary"
          (click)="removeFilter(filter.key)"
        >
          <svg-icon key="close" class="!text-2xs"></svg-icon>
        </div>
      </div>
    </ng-container>
  </div>

  <lla-checkbox-element
    class="block mt-xs md:ml-sm self-end md:self-start"
    label="Advanced filters"
    [value]="!!isAdvancedMode"
    (click)="setAdvancedMode()"
  ></lla-checkbox-element>
</div>
