import { ISelectItem } from '@lla-platform/core/core-ui'

export enum LostTireSoldType {
  NotSet = 'NotSet',
  Yes = 'Yes',
  No = 'No'
}

export const LostTireSoldTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Yes: 'Yes',
  No: 'No'
}

export const LostTireSoldTypeItems: ISelectItem[] = [
  {
    label: LostTireSoldTypeToLabel[LostTireSoldType.NotSet],
    value: LostTireSoldType.NotSet
  },
  {
    label: LostTireSoldTypeToLabel[LostTireSoldType.Yes],
    value: LostTireSoldType.Yes
  },
  {
    label: LostTireSoldTypeToLabel[LostTireSoldType.No],
    value: LostTireSoldType.No
  }
]
