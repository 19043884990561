import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-confirm-simple-mode-dialog',
  templateUrl: './confirm-simple-mode-dialog.component.html',
  styleUrls: ['./confirm-simple-mode-dialog.component.scss']
})
export class ConfirmSimpleModeDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmSimpleModeDialogComponent>) {}
}
