import { IBaseShop } from '@lla-platform/core/core-data-access'
import { Permission } from '../enums/permission.enum'
import { Role } from '../enums/role.enum'
import { IBookmarkItem } from './bookmarks.interface'

export interface IUser {
  id: string
  fullName?: string
  userName: string
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  hasLocations: boolean
  numberOfLocations?: number
  role: Role
  permissions: Permission[]
  bookmarks: IBookmarkItem[]
  created?: Date
  userStates?: string[]
}

export interface IBaseUser {
  id: string
  fullName?: string
  email?: string
  phoneNumber?: string
  created?: Date
  permissions?: Permission[]
  numberOfPermissions?: number
  locations?: IBaseShop[]
  numberOfLocations?: number
}

export interface IBaseUserResponse {
  users: IBaseUser[]
}
