export enum FullReceivableType {
  All = 'All',
  NotRated = 'NotRated',
  LowRisk = 'LowRisk',
  HighRisk = 'HighRisk'
}

export enum ReceivableType {
  NotRated = 'NotRated',
  LowRisk = 'LowRisk',
  HighRisk = 'HighRisk'
}

export const ReceivableTypeToLabel: { [key: string]: string } = {
  NotRated: 'No Rated',
  LowRisk: 'Low Risk',
  HighRisk: 'High Risk'
}
