import { Injectable } from '@angular/core'
import { TableFilterService } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReceivableService } from '../receivable.service'
import {
  GetArInvoicesList,
  GetArInvoicesSummary,
  GetGpPackage,
  GetGpPackageList,
  GetGrossProfit,
  GetGrossProfitList,
  GetInvoice,
  GetPackageUnperformed
} from './ar-invoices.actions'
import { ArInvoicesStateModel } from './ar-invoices.model'
import { IInvoiceGrossProfitExtendedResponse } from '../../interfaces/invoice-gross-profit.interface'
import { IInvoiceGpPackageExtendedResponse } from '../../interfaces/invoice-gp-package.interface'

@State<ArInvoicesStateModel>({
  name: 'arInvoices'
})
@Injectable()
export class ArInvoicesState {
  constructor(
    private receivableService: ReceivableService,
    private tableFilterService: TableFilterService
  ) {}

  @Selector()
  static summaryResponse(state: ArInvoicesStateModel) {
    return state.summaryResponse
  }

  @Selector()
  static listResponse(state: ArInvoicesStateModel) {
    return state.listResponse
  }

  @Selector()
  static grossProfitListResponse(state: ArInvoicesStateModel) {
    return state.grossProfitListResponse
  }

  @Selector()
  static gpPackageListResponse(state: ArInvoicesStateModel) {
    return state.gpPackageListResponse
  }

  @Selector()
  static invoiceResponse(state: ArInvoicesStateModel) {
    return state.invoiceResponse
  }

  @Selector()
  static packageUnperformed(state: ArInvoicesStateModel) {
    return state.packageUnperformed
  }

  @Selector()
  static grossProfit(state: ArInvoicesStateModel): IInvoiceGrossProfitExtendedResponse | undefined {
    if (!state.grossProfit) {
      return
    }

    const maxC1Percent = Math.max(
      ...state.grossProfit.invoiceGrossProfits.map((el) => el.c1?.totalPercent ?? 0)
    )
    const maxC2Percent = Math.max(
      ...state.grossProfit.invoiceGrossProfits.map((el) => el.c2?.totalPercent ?? 0)
    )
    const maxC3Percent = Math.max(
      ...state.grossProfit.invoiceGrossProfits.map((el) => el.c3?.totalPercent ?? 0)
    )
    const maxC4Percent = Math.max(
      ...state.grossProfit.invoiceGrossProfits.map((el) => el.c4?.totalPercent ?? 0)
    )
    const maxC5Percent = Math.max(
      ...state.grossProfit.invoiceGrossProfits.map((el) => el.c5?.totalPercent ?? 0)
    )
    const maxTotalPercent = Math.max(
      ...state.grossProfit.invoiceGrossProfits.map((el) => el.total?.totalPercent ?? 0)
    )

    return {
      timePeriod: state.grossProfit.timePeriod,
      invoiceGrossProfits: state.grossProfit.invoiceGrossProfits.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,
        c1Total: el.c1?.total ?? 0,
        c1Percent: el.c1?.totalPercent ?? 0,
        c1PercentGlobal: ((el.c1?.totalPercent ?? 0) / maxC1Percent) * 100,
        c1PercentFormatted: el.c1?.formattedTotalPercent,
        c2Total: el.c2?.total ?? 0,
        c2Percent: el.c2?.totalPercent ?? 0,
        c2PercentGlobal: ((el.c2?.totalPercent ?? 0) / maxC2Percent) * 100,
        c2PercentFormatted: el.c2?.formattedTotalPercent,
        c3Total: el.c3?.total ?? 0,
        c3Percent: el.c3?.totalPercent ?? 0,
        c3PercentGlobal: ((el.c3?.totalPercent ?? 0) / maxC3Percent) * 100,
        c3PercentFormatted: el.c3?.formattedTotalPercent,
        c4Total: el.c4?.total ?? 0,
        c4Percent: el.c4?.totalPercent ?? 0,
        c4PercentGlobal: ((el.c4?.totalPercent ?? 0) / maxC4Percent) * 100,
        c4PercentFormatted: el.c4?.formattedTotalPercent,
        c5Total: el.c5?.total ?? 0,
        c5Percent: el.c5?.totalPercent ?? 0,
        c5PercentGlobal: ((el.c5?.totalPercent ?? 0) / maxC5Percent) * 100,
        c5PercentFormatted: el.c5?.formattedTotalPercent,
        totalsTotal: el.total?.total ?? 0,
        totalsPercent: el.total?.totalPercent ?? 0,
        totalsPercentFormatted: el.total?.formattedTotalPercent,
        totalsPercentGlobal: ((el.total?.totalPercent ?? 0) / maxTotalPercent) * 100
      })),
      grandTotal: {
        c1Total: state.grossProfit.totalC1?.total ?? 0,
        c1Percent: state.grossProfit.totalC1?.totalPercent ?? 0,
        c1PercentFormatted: state.grossProfit.totalC1?.formattedTotalPercent,
        c2Total: state.grossProfit.totalC2?.total ?? 0,
        c2Percent: state.grossProfit.totalC2?.totalPercent ?? 0,
        c2PercentFormatted: state.grossProfit.totalC2?.formattedTotalPercent,
        c3Total: state.grossProfit.totalC3?.total ?? 0,
        c3Percent: state.grossProfit.totalC3?.totalPercent ?? 0,
        c3PercentFormatted: state.grossProfit.totalC3?.formattedTotalPercent,
        c4Total: state.grossProfit.totalC4?.total ?? 0,
        c4Percent: state.grossProfit.totalC4?.totalPercent ?? 0,
        c4PercentFormatted: state.grossProfit.totalC4?.formattedTotalPercent,
        c5Total: state.grossProfit.totalC5?.total ?? 0,
        c5Percent: state.grossProfit.totalC5?.totalPercent ?? 0,
        c5PercentFormatted: state.grossProfit.totalC5?.formattedTotalPercent,
        totalsTotal: state.grossProfit.grandTotals?.total ?? 0,
        totalsPercent: state.grossProfit.grandTotals?.totalPercent ?? 0,
        totalsPercentFormatted: state.grossProfit.grandTotals?.formattedTotalPercent
      }
    }
  }

  @Selector()
  static gpPackage(state: ArInvoicesStateModel): IInvoiceGpPackageExtendedResponse | undefined {
    if (!state.gpPackage) {
      return
    }

    return {
      timePeriod: state.gpPackage.timePeriod,
      items: state.gpPackage.items.map((el) => ({
        categoryId: el.categoryId,
        categoryName: el.categoryName,
        locationId: el.locationId,
        locationName: el.locationName,
        c1Total: el.c1?.total ?? 0,
        c1Percent: el.c1?.totalPercent ?? 0,
        c1PercentFormatted: el.c1?.formattedTotalPercent,
        c2Total: el.c2?.total ?? 0,
        c2Percent: el.c2?.totalPercent ?? 0,
        c2PercentFormatted: el.c2?.formattedTotalPercent,
        c3Total: el.c3?.total ?? 0,
        c3Percent: el.c3?.totalPercent ?? 0,
        c3PercentFormatted: el.c3?.formattedTotalPercent,
        c4Total: el.c4?.total ?? 0,
        c4Percent: el.c4?.totalPercent ?? 0,
        c4PercentFormatted: el.c4?.formattedTotalPercent,
        c5Total: el.c5?.total ?? 0,
        c5Percent: el.c5?.totalPercent ?? 0,
        c5PercentFormatted: el.c5?.formattedTotalPercent,
        totalsTotal: el.total?.total ?? 0,
        totalsPercent: el.total?.totalPercent ?? 0,
        totalsPercentFormatted: el.total?.formattedTotalPercent
      })),
      grandTotal: {
        c1Total: state.gpPackage.totalC1?.total ?? 0,
        c1Percent: state.gpPackage.totalC1?.totalPercent ?? 0,
        c1PercentFormatted: state.gpPackage.totalC1?.formattedTotalPercent,
        c2Total: state.gpPackage.totalC2?.total ?? 0,
        c2Percent: state.gpPackage.totalC2?.totalPercent ?? 0,
        c2PercentFormatted: state.gpPackage.totalC2?.formattedTotalPercent,
        c3Total: state.gpPackage.totalC3?.total ?? 0,
        c3Percent: state.gpPackage.totalC3?.totalPercent ?? 0,
        c3PercentFormatted: state.gpPackage.totalC3?.formattedTotalPercent,
        c4Total: state.gpPackage.totalC4?.total ?? 0,
        c4Percent: state.gpPackage.totalC4?.totalPercent ?? 0,
        c4PercentFormatted: state.gpPackage.totalC4?.formattedTotalPercent,
        c5Total: state.gpPackage.totalC5?.total ?? 0,
        c5Percent: state.gpPackage.totalC5?.totalPercent ?? 0,
        c5PercentFormatted: state.gpPackage.totalC5?.formattedTotalPercent,
        totalsTotal: state.gpPackage.grandTotals?.total ?? 0,
        totalsPercent: state.gpPackage.grandTotals?.totalPercent ?? 0,
        totalsPercentFormatted: state.gpPackage.grandTotals?.formattedTotalPercent
      }
    }
  }

  @Action(GetArInvoicesSummary)
  getCustomersTypes(ctx: StateContext<ArInvoicesStateModel>, { payload }: GetArInvoicesSummary) {
    return this.receivableService.getArInvoicesSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          summaryResponse: res
        })
      })
    )
  }

  @Action(GetArInvoicesList)
  getArInvoicesList(ctx: StateContext<ArInvoicesStateModel>, { info }: GetArInvoicesList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.receivableService.getArInvoicesList(info).pipe(
      tap((res) => {
        ctx.patchState({
          listResponse: res
        })
      })
    )
  }

  @Action(GetGrossProfitList)
  getGrossProfitList(ctx: StateContext<ArInvoicesStateModel>, { info }: GetGrossProfitList) {
    ctx.patchState({
      grossProfitListResponse: undefined
    })
    return this.receivableService.getGrossProfitList(info).pipe(
      tap((res) => {
        ctx.patchState({
          grossProfitListResponse: res
        })
      })
    )
  }

  @Action(GetGpPackageList)
  getGpPackageList(ctx: StateContext<ArInvoicesStateModel>, { info }: GetGpPackageList) {
    ctx.patchState({
      gpPackageListResponse: undefined
    })
    return this.receivableService.getGpPackageList(info).pipe(
      tap((res) => {
        ctx.patchState({
          gpPackageListResponse: res
        })
      })
    )
  }

  @Action(GetInvoice)
  getInvoice(ctx: StateContext<ArInvoicesStateModel>, { invoiceId }: GetInvoice) {
    return this.receivableService.getInvoice(invoiceId).pipe(
      tap((res) => {
        ctx.patchState({
          invoiceResponse: res
        })
      })
    )
  }

  @Action(GetGrossProfit)
  getGrossProfit(ctx: StateContext<ArInvoicesStateModel>, { payload }: GetGrossProfit) {
    return this.receivableService.getGrossProfit(payload).pipe(
      tap((res) => {
        ctx.patchState({
          grossProfit: res
        })
      })
    )
  }

  @Action(GetGpPackage)
  getGpPackage(ctx: StateContext<ArInvoicesStateModel>, { payload }: GetGpPackage) {
    ctx.patchState({
      gpPackage: undefined
    })
    return this.receivableService.getGpPackage(payload).pipe(
      tap((res) => {
        ctx.patchState({
          gpPackage: res
        })
      })
    )
  }

  @Action(GetPackageUnperformed)
  getPackageUnperformed(ctx: StateContext<ArInvoicesStateModel>, { payload }: GetPackageUnperformed) {
    ctx.patchState({
      packageUnperformed: undefined
    })
    return this.receivableService.getPackageUnperformed(payload).pipe(
      tap((res) => {
        ctx.patchState({
          packageUnperformed: res
        })
      })
    )
  }
}
