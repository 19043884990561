import { ISelectItem } from '@lla-platform/core/core-ui'

export enum TireLevelType {
  NotSet = 'NotSet',
  Level1 = 'Level1',
  Level2 = 'Level2',
  Level3 = 'Level3',
  Level4 = 'Level4',
  Level5 = 'Level5',
  Level6 = 'Level6'
}

export const TireLevelTypeToLabel: { [key: string]: string } = {
  NotSet: '',
  Level1: 'Level 1',
  Level2: 'Level 2',
  Level3: 'Level 3',
  Level4: 'Level 4',
  Level5: 'Level 5',
  Level6: 'Level 6'
}

export const TireLevelTypeItems: ISelectItem[] = [
  {
    label: TireLevelTypeToLabel[TireLevelType.Level1],
    value: TireLevelType.Level1
  },
  {
    label: TireLevelTypeToLabel[TireLevelType.Level2],
    value: TireLevelType.Level2
  },
  {
    label: TireLevelTypeToLabel[TireLevelType.Level3],
    value: TireLevelType.Level3
  },
  {
    label: TireLevelTypeToLabel[TireLevelType.Level4],
    value: TireLevelType.Level4
  },
  {
    label: TireLevelTypeToLabel[TireLevelType.Level5],
    value: TireLevelType.Level5
  },
  {
    label: TireLevelTypeToLabel[TireLevelType.Level6],
    value: TireLevelType.Level6
  }
]
