import { New_TableOperator } from '@lla-platform/core/core-data-access'
import { ISelectItem } from '@lla-platform/core/core-ui'

const New_TableOperatorToLabel: { [key in New_TableOperator]: string } = {
  LessThan: 'Less than',
  LessThanOrEqual: 'Less than or equal',
  Equals: 'Equals',
  NotEquals: 'Not equal',
  GreaterThanOrEqual: 'Greater than or equal',
  GreaterThan: 'Greater than',
  Contains: 'Contains',
  StartsWith: 'Starts with',
  EndsWith: 'Ends with',
  Period: 'Period'
}

export const New_TableOperatorToSentence: { [key in New_TableOperator]: string } = {
  LessThan: 'less than',
  LessThanOrEqual: 'less than or equal to',
  Equals: 'equal to',
  NotEquals: 'not equal to',
  GreaterThanOrEqual: 'greater than or equal to',
  GreaterThan: 'greater than',
  Contains: 'contains',
  StartsWith: 'starts with',
  EndsWith: 'ends with',
  Period: 'in'
}

export const New_StringOperators: ISelectItem[] = [
  {
    label: New_TableOperatorToLabel.Contains,
    value: 'Contains'
  },
  {
    label: New_TableOperatorToLabel.Equals,
    value: 'Equals'
  },
  {
    label: New_TableOperatorToLabel.NotEquals,
    value: 'NotEquals'
  },
  {
    label: New_TableOperatorToLabel.StartsWith,
    value: 'StartsWith'
  },
  {
    label: New_TableOperatorToLabel.EndsWith,
    value: 'EndsWith'
  }
]

export const New_NumberOperators: ISelectItem[] = [
  {
    label: New_TableOperatorToLabel.LessThanOrEqual,
    value: 'LessThanOrEqual'
  },
  {
    label: New_TableOperatorToLabel.LessThan,
    value: 'LessThan'
  },
  {
    label: New_TableOperatorToLabel.Equals,
    value: 'Equals'
  },
  {
    label: New_TableOperatorToLabel.NotEquals,
    value: 'NotEquals'
  },
  {
    label: New_TableOperatorToLabel.GreaterThan,
    value: 'GreaterThan'
  },
  {
    label: New_TableOperatorToLabel.GreaterThanOrEqual,
    value: 'GreaterThanOrEqual'
  }
]

export const TotalRowUniqueId = 'advanced_table_total_row_unique_id'

export const BooleanEnumItems: ISelectItem[] = [
  {
    label: 'True',
    value: true
  },
  {
    label: 'False',
    value: false
  }
]
