import { ITableFilter } from './advanced-table.interface'
import { INew_TableFilterRequest } from './table-filter.interface'

export interface IPaginationFilteredOrderBy {
  property: string
  direction: 'Ascending' | 'Descending'
}

export interface IPaginationBaseRequest {
  orderBy?: IPaginationFilteredOrderBy[]
  top: number
  skip: number
}

export interface IPaginationFilteredRequest extends IPaginationBaseRequest {
  filters: ITableFilter
}

export interface INewPaginationFilteredRequest extends IPaginationBaseRequest {
  filters: INew_TableFilterRequest
}

export interface IPaginationFilteredResponse {
  count: number
}
