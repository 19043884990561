<div
  class="{{ extraClass }} w-full flex items-center text-txt font-semibold text-sm leading-sm"
  [ngClass]="{
    'justify-end text-right': rightAlign,
    'justify-center text-center': centerAlign,
    'cursor-pointer': sortable,
    '!text-primary': isHeaderActive()
  }"
  (click)="menuTrigger.openMenu()"
>
  <span>{{ title }}</span>
  <span class="pl-[6px] pb-[1px]" *ngIf="isHeaderActive()">
    <svg-icon
      key="arrow-up-full"
      class="!text-2xs"
      [ngClass]="{ 'rotate-180': sortState.direction === 'desc' }"
    ></svg-icon>
  </span>
</div>
<div [matMenuTriggerFor]="sortable ? sortMenu : null" #menuTrigger="matMenuTrigger"></div>

<!-- Filter Menu -->
<mat-menu #sortMenu="matMenu" class="table-header-sort-menu">
  <ng-template matMenuContent>
    <div
      class="p-base min-w-[200px]"
      (click)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
    >
      <div *ngFor="let info of sortInfo" class="mt-sm first:mt-0 flex items-center justify-between">
        <div class="mr-base font-semibold text-sm leading-sm text-txt">
          {{ info.title ?? title }}
        </div>
        <div class="flex justify-end items-center">
          <div
            class="mr-xs sort-pill"
            [ngClass]="{
              'sort-pill-active':
                sortState && sortState.active === info.columnName && sortState.direction === 'desc'
            }"
            (click)="
              sortChanged.emit(
                sortStateService.update({ active: info.columnName, direction: 'desc' }, sortState)
              )
            "
          >
            <div class="sort-pill-title">High to Low</div>
          </div>

          <div
            class="sort-pill"
            [ngClass]="{
              'sort-pill-active':
                sortState && sortState.active === info.columnName && sortState.direction === 'asc'
            }"
            (click)="
              sortChanged.emit(
                sortStateService.update({ active: info.columnName, direction: 'asc' }, sortState)
              )
            "
          >
            <div class="sort-pill-title">Low to High</div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</mat-menu>
