import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IButtonItem } from './button-group.interface'

@Component({
  selector: 'lla-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss']
})
export class ButtonGroupComponent {
  @Input() buttons: IButtonItem[] = []
  @Input() containerClass = ''
  @Input() itemClass = ''
  @Input() selectedButtonValue: any

  @Output() selectedButtonChanged = new EventEmitter()
}
