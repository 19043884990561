import { ISelectItem } from '@lla-platform/core/core-ui'

export enum LostTireCallType {
  NotSet = 'NotSet',
  InPerson = 'InPerson',
  PhoneCall = 'PhoneCall'
}

export const LostTireCallTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  InPerson: 'In Person',
  PhoneCall: 'Phone Call'
}

export const LostTireCallTypeItems: ISelectItem[] = [
  {
    label: LostTireCallTypeToLabel[LostTireCallType.NotSet],
    value: LostTireCallType.NotSet
  },
  {
    label: LostTireCallTypeToLabel[LostTireCallType.InPerson],
    value: LostTireCallType.InPerson
  },
  {
    label: LostTireCallTypeToLabel[LostTireCallType.PhoneCall],
    value: LostTireCallType.PhoneCall
  }
]
