<div class="modal-container relative p-base">
  <!-- Title -->
  <div class="text-base leading-base text-txt">Add Bookmark</div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col mt-base">
    <lla-input controlName="name" label="Bookmark title" size="default"></lla-input>

    <div class="flex items-center justify-end mt-xl">
      <lla-button size="medium-shrink" variant="ghost" type="button" (click)="dialogRef.close()">
        Cancel
      </lla-button>
      <lla-button
        class="ml-2xl"
        type="submit"
        variant="ghost"
        size="medium-shrink"
        extraBtnClass="!text-primary"
        loadingExtraClass="!border-primary"
        [loading]="isLoading"
      >
        Add
      </lla-button>
    </div>
  </form>
</div>
